import React, { useEffect, useState } from 'react';
import MainContainer from '../../layout/MainContainer';
import CloseIcon from '@mui/icons-material/Close';
import CouponForm from './feature/CouponForm';
import { useNavigate, useParams } from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';
import {
    Autocomplete,
    CardMedia,
    FormControlLabel,
    Input,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    TextField,
} from '@mui/material';
import {
    STORAGE_KEYS,
    errorToast,
    getFromStorage,
    successToast,
} from '../../helpers';
import { generateEncryptedKeyBody } from '../../utils/crypto';
import { CommonBody, Coupon } from '../../types/General';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { UploadMedia } from '../../utils/mediaUpload';
import AddIcon from '@mui/icons-material/Add';
import { isNumber, isString } from '../../utils/validation';
import {
    useAddCouponMutation,
    useLazyGetCouponByIdQuery,
    useUpdateCouponMutation,
} from '../../services/main';
import {
    useLazyGetAllOrganisationQuery,
    useLazyGetOrganisationQuery,
} from '../../services/organisation';
import { User } from '../../types/User';
import moment from 'moment';
import useAuth from '../../hooks/useAuth';
import { setCredentials } from '../../reducers/authSlice';
import { useAppDispatch } from '../../hooks/store';

interface Option {
    id: number;
    value: string;
}

const AddCoupon = () => {
    const { id } = useParams();
    const user = useAuth();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [addCoupon, { isLoading }] = useAddCouponMutation();
    const [updateCoupon, updateCouponData] = useUpdateCouponMutation();
    const [couponById] = useLazyGetCouponByIdQuery();
    const [getOrganisation] = useLazyGetAllOrganisationQuery();

    const [error, setError] = useState<boolean>(false);
    const [couponType, setCouponType] = useState('2');
    const [discountSelect, setDiscountSelect] = useState('1');
    const [coupon, setCoupon] = useState<Coupon | null>(null);
    const [couponStatus, setCouponStatus] = useState<number>(3);
    const [organisation, setOrganisation] = useState<User[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<User[]>([]);
    const [selectedOption, setSelectedOption] = useState<User | null>(null);
    const [newArray, setNewArray] = useState<any[]>([]);
    const [selectedB2b, setSelectedB2b] = useState('');

    const handleChange = (event: SelectChangeEvent) => {
        setCouponType(event.target.value as string);
    };

    const handleChangeDiscount = (event: SelectChangeEvent) => {
        setDiscountSelect(event.target.value as string);
    };

    //for image upload
    const handleImageUpload = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const files = event.target;
        const file = files?.files?.length ? files?.files[0] : '';

        // Check if a file is selected
        if (file) {
            // Check if the selected file is an image
            if (file.type.startsWith('image/')) {
                const res = await UploadMedia(file);
                if (res?.statusCode === 200) {
                    formik.setFieldValue('image', res?.data);
                } else {
                    errorToast(res?.message);
                }
            } else {
                // Display an error message for non-image files
                errorToast('Please Try Again');
            }
        }
    };

    const getAllOrganisation = async () => {
        const token = getFromStorage(STORAGE_KEYS.token);

        dispatch(
            setCredentials({
                user: user,
                token: token,
            })
        );

        try {
            const res = await getOrganisation({}).unwrap();
            if (res?.statusCode === 200) {
                setOrganisation(res?.data?.subAdmin || []);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getAllOrganisation();
    }, []);

    const onSelect = (selectedList: User[], selectedItem: User) => {
        setSelectedOptions(selectedList);
    };
    const onRemove = (selectedList: User[], removedItem: User) => {
        setSelectedOptions(selectedList);
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            couponCode: coupon?.code || '',
            startDate: coupon?.startDate
                ? moment(coupon?.startDate).format('YYYY-MM-DD')
                : moment().format('YYYY-MM-DD'),
            endDate: coupon?.endDate
                ? moment(coupon?.endDate).format('YYYY-MM-DD')
                : '',
            description: coupon?.description || '',
            image: coupon?.image || '',
            couponPrice: coupon?.effectiveValue || '',
            quantity: '1',
            // price: coupon?.price,
            // sellPrice: coupon?.sellPrice,
        },
        validationSchema: Yup.object({
            // image:
            //   user?.role === 1
            //     ? Yup.string().required("Image name is required")
            //     : Yup.string().notRequired(),

            couponCode: Yup.string()
                .required('This field is required')
                .min(2, 'Minimum 2 characters are required')
                .max(80, 'Maximum 80 character are allowed'),
            couponPrice: Yup.string().required('This field is required'),
            // .min(2, "Minimum 2 digits are required")
            // .max(80, "Maximum 80 digits are allowed")
            quantity: Yup.string().required('This field is required'),
            description: Yup.string()
                .required('This field is required')
                .min(2, 'Minimum 2 characters are required')
                .max(300, 'Maximum 300 character are allowed'),
            startDate: Yup.string().required('This field is required'),
            // price: Yup.string().required('This field is required'),
            // endDate: Yup.string().required("This field is required"),
        }),
        onSubmit: async () => {
            formik.setSubmitting(true);
            // selectedOptions.map((user) => {
            //   const { _id, companyName, companyWebSite } = user;
            //   newArray.push({ _id, companyName, companyWebSite });
            // });

            // if (formik.values.startDate > formik.values.endDate) {
            //   errorToast("Please select valid dates");
            //   return;
            // }
            if (
                error &&
                Number(discountSelect) === 2 &&
                Number(formik.values.couponPrice) > 100
            ) {
                return;
            }

            if (!selectedOptions?.length && user?.role === 1) {
                setError(true);
                errorToast('Choose organization to assign coupon first');
                return;
            }
            setError(false);
            let body = {
                image:
                    !formik.values.image &&
                    (user?.role === 2 || user?.role === 3)
                        ? user?.image
                        : !formik.values.image && user?.role === 1
                        ? ''
                        : formik.values.image,
                description: formik.values.description,
                effectiveValue: formik.values.couponPrice,
                couponType: couponType, //2 for direct 1 for indirect
                discountType: Number(discountSelect),
                ...(user?.role === 1 ? { assignedTo: selectedOptions } : {}),
                startDate: formik.values.startDate,
                ...(formik.values.endDate
                    ? { endDate: formik.values.endDate }
                    : {}),
                status: couponStatus,
                code: formik.values.couponCode,
                quantity: formik.values.quantity,
                
            };
            console.log(body, 'body');

            if (id) {
                try {
                    let encryptedBody = generateEncryptedKeyBody(
                        body
                    ) as CommonBody;
                    const response = await updateCoupon({
                        body: encryptedBody,
                        id,
                    }).unwrap();
                    if (response?.statusCode === 200) {
                        successToast('Coupon Updated Successfully');
                        formik.resetForm();
                        navigate('/manage-coupons');
                    } else {
                        errorToast(response?.message || '');
                    }
                } catch (error: any) {
                    errorToast(error?.data?.message || '');
                }
            } else {
                try {
                    let encryptedBody = generateEncryptedKeyBody(
                        body
                    ) as CommonBody;

                    const response = await addCoupon(encryptedBody).unwrap();
                    if (response?.statusCode === 200) {
                        successToast('Coupon Added Successfully');
                        formik.resetForm();
                        navigate('/manage-coupons');
                    } else {
                        errorToast(response?.message || '');
                    }
                } catch (error: any) {
                    errorToast(error?.data?.message || '');
                }
            }
        },
    });
    const getCouponByById = async (id: string) => {
        try {
            const response = await couponById({ id }).unwrap();
            if (response?.statusCode === 200) {
                setCoupon(response?.data);
                setCouponType(String(response?.data?.couponType));
                setCouponStatus(Number(response?.data?.status));
                setDiscountSelect(String(response?.data?.discountType));
                setSelectedOptions(response?.data?.assignedTo || []);
                setSelectedOption(response?.data?.assignedTo?.[0]);

                // const selectedOrganisations = organisation.filter((org) =>
                //   response?.data?.assignedTo.some(
                //     (selectedOrg) => selectedOrg._id === org._id
                //   )
                // );
                // console.log(selectedOrganisations, "selectedOrganisations");

                // setSelectedOptions(selectedOrganisations);
            }
        } catch (error: any) {
            errorToast(error?.data?.message || '');
        }
    };

    const handleChangeB2b = (
        event: React.ChangeEvent<object>,
        value: User | null
    ) => {
        if (value !== null) {
            setSelectedOption(value);
            setSelectedOptions([value]);
        }
    };

    useEffect(() => {
        if (id && id !== 'add') {
            getCouponByById(id);
        }
    }, []);

    useEffect(() => {
        if (coupon) {
            setCouponStatus(Number(coupon?.status));
        }
    }, [coupon]);

    return (
        <MainContainer>
            <div className="coupon coupon_dtl_page">
                <div className="sec_head">
                    <h2>{id ? 'Edit' : 'Add'} Coupon</h2>
                    <div className="btn_group">
                        <div
                            className="btn btn_sm btn_primary"
                            onClick={() =>
                                navigate(
                                    id
                                        ? `/manage-coupons/coupon-detail/${id}`
                                        : '/manage-coupons'
                                )
                            }
                        >
                            Back
                        </div>
                    </div>
                </div>
                <div className="coupon_flx gap_m">
                    <div className="form_control form_control_bg w_50">
                        {formik.values.image ? (
                            <div className="coupon_card w_100">
                                <div className="coupon_card_dtl">
                                    <figure>
                                        {/* <img src="/images/bar_code.svg" alt="" /> */}
                                    </figure>
                                    <div className="coupon_dtl fs_28 text_center">
                                        <figure className="uploaded_coupon">
                                            <CardMedia
                                                component="img"
                                                image={formik.values.image}
                                                alt="photo"
                                            />
                                        </figure>
                                    </div>
                                    <div className="coupon_btn"></div>
                                </div>
                                <div className="coupon_avl">
                                    <p
                                        className="cursor_pointer"
                                        onClick={() => {
                                            formik.setFieldValue('image', '');
                                        }}
                                    >
                                        Remove Coupon Image
                                    </p>
                                </div>
                            </div>
                        ) : (
                            <div className="coupon_card w_100">
                                <Input
                                    sx={{ display: 'none' }}
                                    id="icon-button-file"
                                    type="file"
                                    inputProps={{
                                        accept: 'image/png,image/jpeg',
                                    }}
                                    onChange={handleImageUpload}
                                />
                                <label
                                    htmlFor="icon-button-file"
                                    style={{ cursor: 'pointer' }}
                                >
                                    <div className="coupon_card_dtl">
                                        <figure>
                                            {/* <img src="/images/bar_code.svg" alt="" /> */}
                                        </figure>
                                        <div className="coupon_dtl fs_28 text_center">
                                            <AddIcon />
                                            {/* <h2>
                        Special <span className="d_block"> Offer</span>
                      </h2> */}
                                            {/* <p className="code">CODE : 26473</p>
                      <p>Valid upto 31st Dec 2023</p> */}
                                        </div>
                                        <div className="coupon_btn">
                                            {/* <button className="btn btn_primary">50%</button> */}
                                        </div>
                                    </div>
                                </label>
                                <div className="coupon_avl">
                                    <label htmlFor="icon-button-file">
                                        <p className="cursor_pointer">
                                            Add Coupon Image
                                        </p>
                                        {/* {!formik.values.image && error && user?.role === 1 ? (
                      <h6 className="err_msg">This field is required</h6>
                    ) : (
                      ""
                    )} */}
                                    </label>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="coupon_status sec_head  ">
                        <h2>Coupon Status</h2>
                        <div className="cpn_status_check">
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                // defaultValue={3}
                                name="radio-buttons-group"
                                value={couponStatus}
                            >
                                <FormControlLabel
                                    className="radio_check"
                                    value={1}
                                    control={<Radio />}
                                    label="Redeemed"
                                    onChange={() => setCouponStatus(1)}
                                />
                                <FormControlLabel
                                    className="radio_check"
                                    value={2}
                                    control={<Radio />}
                                    label="Reserved"
                                    onChange={() => setCouponStatus(2)}
                                />
                                <FormControlLabel
                                    className="radio_check"
                                    value={3}
                                    control={<Radio />}
                                    label="Available"
                                    onChange={() => setCouponStatus(3)}
                                />
                            </RadioGroup>
                        </div>
                    </div>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div className="coupon_frm_wrp">
                        <div className="form_group gap_m">
                            <div className="form_control form_control_bg w_50">
                                <label>
                                    Coupon Code
                                    {/* <Switch {...label} defaultChecked /> */}
                                </label>
                                <TextField
                                    hiddenLabel
                                    placeholder="Coupon Code"
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    className="text_field"
                                    name="couponCode"
                                    id="couponCode"
                                    onChange={(val) => {
                                        if (
                                            val.target.value === ' ' ||
                                            val.target.value === '.'
                                        ) {
                                        } else {
                                            formik.handleChange(val);
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.couponCode}
                                    helperText={
                                        formik.touched.couponCode &&
                                        formik.errors.couponCode
                                    }
                                />
                            </div>
                            <div className="form_control form_control_bg w_50">
                                <label>Value</label>
                                <TextField
                                    hiddenLabel
                                    placeholder="Value"
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    className="text_field"
                                    name="couponPrice"
                                    id="couponPrice"
                                    onChange={(val) => {
                                        if (
                                            val.target.value === ' ' ||
                                            val.target.value === '.'
                                        ) {
                                        } else if (
                                            isNumber(val.target.value) ||
                                            val.target.value === '0'
                                        ) {
                                            formik.handleChange(val);
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.couponPrice}
                                    helperText={
                                        formik.touched.couponPrice &&
                                        formik.errors.couponPrice
                                    }
                                />
                                {error &&
                                Number(discountSelect) === 2 &&
                                Number(formik.values.couponPrice) > 100 ? (
                                    <h6 className="err_msg">
                                        Please enter a number between 0 and 100.
                                    </h6>
                                ) : (
                                    ''
                                )}
                            </div>                
                            
                            <div className="form_control form_control_bg w_50">
                                <label>Quantity</label>
                                <TextField
                                    disabled
                                    hiddenLabel
                                    placeholder="Add Quantity"
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    className="text_field"
                                    name="quantity"
                                    id="quantity"
                                    onChange={(val) => {
                                        if (
                                            val.target.value === ' ' ||
                                            val.target.value === '.'
                                        ) {
                                        } else if (isNumber(val.target.value)) {
                                            formik.handleChange(val);
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.quantity}
                                    helperText={
                                        formik.touched.quantity &&
                                        formik.errors.quantity
                                    }
                                />
                            </div>
                            <div className="form_control form_control_bg w_50">
                                <label>Discount</label>
                                <Select
                                    fullWidth
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    label="social-account"
                                    value={discountSelect}
                                    onChange={handleChangeDiscount}
                                >
                                    <MenuItem value={1}>Fixed</MenuItem>
                                    <MenuItem value={2}>Percentage</MenuItem>
                                </Select>
                            </div>
                            <div className="form_control form_control_bg w_50">
                                <label>Start Date</label>
                                <TextField
                                    // disabled
                                    inputProps={{
                                        min:
                                            coupon && id
                                                ? coupon.startDate.split('T')[0]
                                                : new Date()
                                                      .toISOString()
                                                      .split('T')[0],
                                    }}
                                    onKeyDown={(e) => e.preventDefault()}
                                    hiddenLabel
                                    placeholder="Start Date"
                                    fullWidth
                                    type="date"
                                    variant="outlined"
                                    className="text_field"
                                    name="startDate"
                                    id="startDate"
                                    value={formik.values.startDate}
                                    onChange={formik.handleChange}
                                    helperText={
                                        formik.touched.startDate &&
                                        formik.errors.startDate
                                    }
                                />
                            </div>
                            <div className="form_control form_control_bg w_50">
                                <label>End Date</label>
                                <TextField
                                    // onKeyDown={(e) => e.preventDefault()}
                                    hiddenLabel
                                    placeholder="End Date"
                                    fullWidth
                                    type="date"
                                    variant="outlined"
                                    className="text_field"
                                    name="endDate"
                                    id="endDate"
                                    inputProps={{
                                        min:
                                            formik.values.endDate ||
                                            new Date()
                                                .toISOString()
                                                .split('T')[0],
                                        max: (() => {
                                            const maxDate = new Date();
                                            maxDate.setFullYear(
                                                maxDate.getFullYear() + 10
                                            ); // Adjust this if you want a different maximum year
                                            return maxDate
                                                .toISOString()
                                                .split('T')[0];
                                        })(),
                                    }}
                                    value={formik.values.endDate}
                                    onChange={formik.handleChange}
                                    helperText={
                                        formik.touched.endDate &&
                                        formik.errors.endDate
                                    }
                                />
                            </div>
                            <div className="form_control form_control_bg w_50">
                                <label>Coupon Type</label>
                                <Select
                                    fullWidth
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    label="social-account"
                                    value={couponType}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={2}>Direct</MenuItem>
                                    <MenuItem value={1}>Indirect</MenuItem>
                                </Select>
                            </div>
                            {user?.role === 1 ? (
                                <div className="form_control form_control_bg w_50">
                                    <label>Assign To</label>
                                    <Autocomplete
                                        className="prfl_autocmplt"
                                        disablePortal
                                        id="combo-box-demo"
                                        onChange={handleChangeB2b}
                                        value={selectedOption}
                                        options={
                                            organisation?.length
                                                ? organisation
                                                : []
                                        }
                                        getOptionLabel={(option) =>
                                            option?.companyName
                                        }
                                        sx={{ width: 'auto' }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Organization"
                                            />
                                        )}
                                    />
                                    {/* {!selectedB2b && error ? (
                    <h6 className="err_msg">This field is required</h6>
                  ) : (
                    ""
                  )} */}
                                    {/* <Multiselect
                    className="multi_select"
                    options={organisation}
                    displayValue="companyName"
                    selectedValues={selectedOptions}
                    onSelect={onSelect}
                    onRemove={onRemove}
                  /> */}
                                </div>
                            ) : null}

                            <div className="form_control form_control_bg w_100">
                                <label>Short Note</label>
                                <TextField
                                    hiddenLabel
                                    placeholder="Enter Short Note"
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    className="text_field"
                                    name="description"
                                    id="description"
                                    onChange={(val) => {
                                        if (
                                            val.target.value === ' ' ||
                                            val.target.value === '.'
                                        ) {
                                        } else if (isString(val.target.value)) {
                                            formik.handleChange(val);
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.description}
                                    helperText={
                                        formik.touched.description &&
                                        formik.errors.description
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="submit_btn">
                        <button
                            className="btn btn_primary"
                            onClick={() => setError(true)}
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </MainContainer>
    );
};

export default AddCoupon;
