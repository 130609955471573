import {
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import Pagination from "../../../components/Pagination";
  import { Link, useNavigate } from "react-router-dom";
  import VisibilityIcon from "@mui/icons-material/Visibility";
  import DeleteIcon from "@mui/icons-material/Delete";
  import Tabs from "@mui/material/Tabs";
  import Tab from "@mui/material/Tab";
  import Box from "@mui/material/Box";
  import { Typography } from "@mui/material";
  import useAuth from "../../../hooks/useAuth";
  import { useAppDispatch } from "../../../hooks/store";
  import { STORAGE_KEYS, getFromStorage } from "../../../helpers";
  import { setCredentials } from "../../../reducers/authSlice";
  import {
    useLazyGetClaimedAssetsQuery,
    useLazyGetCategoryQuery,
    useLazyGetSubCategoryByIdQuery,
    useUpdateAdminNftStatusMutation,
  } from "../../../services/main";
  import { Category, Nft } from "../../../types/General";
  import Loader from "../../../constants/Loader";
  import SearchBar from "../../../components/SearchBar";
  import FilterComp from "../../../components/filter/filter";
  import { isValidInput } from "../../../utils/validation";
  import moment from "moment";
  import { updateStatus } from "../../../utils/commonFunctions";
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  
  export default function ClaimedAssets() {
    const label = { inputProps: { "aria-label": "Switch demo" } };
  
    const user = useAuth();
    console.log({user})
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
  
    const [getCategory] = useLazyGetCategoryQuery();
    const [getAdminNftMutation, { isLoading }] = useLazyGetClaimedAssetsQuery();
    const [updateStatusMutation] = useUpdateAdminNftStatusMutation();
    const [getSubcategory] = useLazyGetSubCategoryByIdQuery();
  
    const [page, setPage] = useState(1);
    const [nftData, setNftData] = useState<Nft[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [sortSelect, setSortSelect] = useState("1");
    const [positionSelect, setPositionSelect] = useState("2");
    const [categories, setCategories] = useState<Category[]>([]);
    const [category, setCatogry] = useState<string>("");
    const [selectedCate, setSelectCate] = useState("");
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(0);
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
    const [searchTerm, setSearchTerm] = useState<string>("");
  
    let totalPages = Math.ceil(totalCount / 10);
  
    const onPageChange = (newPage: number) => {
      setPage(newPage);
    };
  
    function createData(Name: string, CarbonCredit: string) {
      return { Name, CarbonCredit };
    }
    const rows = [
      createData("Uni waste", "60"),
      createData("Uni waste", "60"),
      createData("Uni waste", "60"),
      createData("Uni waste", "60"),
    ];
  
    const handleChange2 = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };
  
    const getAllCategory = async () => {
      const token = getFromStorage(STORAGE_KEYS.token);
      dispatch(
        setCredentials({
          user: user,
          token: token,
        })
      );
      try {
        const res = await getCategory({
          size: 10,
          page: page,
          query: debouncedSearchTerm.trim(),
        }).unwrap();
        if (res?.statusCode === 200) {
          setCategories(res?.data?.category || []);
          setCatogry(res?.data?.category[0]?._id);
        }
      } catch (error) {
        console.log(error);
      }
    };
  
    const getAdminNft = async () => {
      setLoading(true);
      const token = getFromStorage(STORAGE_KEYS.token);
      dispatch(
        setCredentials({
          user: user,
          token: token,
        })
      );
      try {
        const res = await getAdminNftMutation({
          filter: value,
          companyName: user!.companyName,
          size: 10,
          page: page,
          query: debouncedSearchTerm.trim(),
          categoryId: selectedCate,
          sort: sortSelect !== "default" ? sortSelect : "",
          sortType:
            sortSelect !== "default"
              ? positionSelect == "1"
                ? "asc"
                : "desc"
              : "",
        }).unwrap();

        console.log({res})
        if (res?.statusCode === 200) {
          setLoading(false);
          setNftData(res?.data?.data || []);
          setTotalCount(res?.data?.count);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
  
    useEffect(() => {
      getAllCategory();
    }, []);
  
    const getAllSubCategory = async (id: string) => {
      const token = getFromStorage(STORAGE_KEYS.token);
      dispatch(
        setCredentials({
          user: user,
          token: token,
        })
      );
      if (id) {
        try {
          const res = await getSubcategory({
            id,
            size: 10,
            page: 1,
            query: "",
          }).unwrap();
          if (res?.statusCode === 200) {
            setCategories(res?.data?.category || []);
          }
        } catch (error) {
          console.log(error);
        }
      }
    };
  
    useEffect(() => {
      if (category) {
        getAllSubCategory(category);
      }
    }, [category]);
  
    useEffect(() => {
        console.log('idhar aa raha hai ')
      getAdminNft();
    }, [
      debouncedSearchTerm,
      page,
      positionSelect,
      sortSelect,
      selectedCate,
      value,
      user
    ]);
    return (
      <>
        <Loader isLoad={isLoading || loading} />
        <div className="rpt_card mng_role_wrp ">
          <div className="dashboard sec_head fs_22">
            <h2>Claimed Asset</h2>
            <div className="bck_btn">
              <SearchBar
                searchTerm={searchTerm}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                value={searchTerm}
                onCross={() => setSearchTerm("")}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
              />
            </div>
          </div>
  
          <div className="custom_tabs">
            {/* <Tabs
              value={value}
              onChange={handleChange2}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Passive" {...a11yProps(0)} />
              <Tab label="Minted" {...a11yProps(1)} />
            </Tabs> */}
            <CustomTabPanel value={value} index={0}>
              <FilterComp
                selectedCate={selectedCate}
                setSelectCate={setSelectCate}
                categories={categories}
                setPositionSelect={setPositionSelect}
                sortSelect={sortSelect}
                setSortSelect={setSortSelect}
                positionSelect={positionSelect}
              />
              <TableContainer className="table_container">
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                  className="table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Image</TableCell>
                      <TableCell>NFT Name</TableCell>
                      <TableCell>NFT Status</TableCell>
                      <TableCell>Sub-Category Name</TableCell>
                      <TableCell>Carban Credit</TableCell>
                      <TableCell>I2E Point</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {nftData?.length
                      ? nftData?.map((item, i) => (
                          <TableRow
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              navigate(
                                `/claimed-assets/view-nft/${item?._id}/${value}`
                              )
                            }
                          >
                            <TableCell align="center">
                              {(page - 1) * 10 + i + 1}
                            </TableCell>
  
                            <TableCell>
                              <figure className="nft_img">
                                <img src={item?.image || ""} alt="" />
                              </figure>
                            </TableCell>
                            <TableCell>{item?.name || ""}</TableCell>
  
                            <TableCell>
                              {item?.sellType === 1 ? "On Bid" : "Private"}
                            </TableCell>
                            <TableCell>{item?.subCategory?.name || ""}</TableCell>
                            <TableCell align="center">
                              {item?.carbonCredit || "0"}
                            </TableCell>
                            <TableCell>{item?.basePrice || "0"}</TableCell>
                            {/* <TableCell>
                            <p>
                              kishore kumar
                              <span className="d_block">
                                kishore@apptunix.com
                              </span>
                              <span className="d_block">9877454492</span>
                            </p>
                          </TableCell> */}
                            <TableCell style={{ minWidth: "100px" }}>
                              {moment(item?.createdAt).format("MMM Do YY")}
                            </TableCell>
                            <TableCell onClick={(e) => e.stopPropagation()}>
                              <Switch
                                {...label}
                                size="small"
                                checked={!item?.isBlocked}
                                onChange={() =>
                                  updateStatus(
                                    item?._id,
                                    !item?.isBlocked,
                                    updateStatusMutation,
                                    setNftData,
                                    nftData
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <ul className="action_btn">
                                <li>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      navigate(
                                        `/manage-nft/view-nft/${item?._id}`
                                      )
                                    }
                                  >
                                    <VisibilityIcon />
                                  </button>
                                </li>
  
                                {/* <li>
                                <button type="button">
                                  <DeleteIcon />
                                </button>
                              </li> */}
                              </ul>
                            </TableCell>
                          </TableRow>
                        ))
                      : !loading &&
                        !nftData?.length && (
                          <TableRow>
                            <TableCell align="center" colSpan={5}>
                              No Data Found
                            </TableCell>
                          </TableRow>
                        )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                setPage={setPage}
                module={nftData}
                page={page}
                onPageChange={onPageChange}
                totalPages={totalPages}
                totalCount={totalCount}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <FilterComp
                selectedCate={selectedCate}
                setSelectCate={setSelectCate}
                categories={categories}
                setPositionSelect={setPositionSelect}
                sortSelect={sortSelect}
                setSortSelect={setSortSelect}
                positionSelect={positionSelect}
              />
              <TableContainer className="table_container">
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                  className="table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Image</TableCell>
                      <TableCell>NFT Name</TableCell>
                      <TableCell>NFT Status</TableCell>
                      <TableCell>Sub-Category Name</TableCell>
                      <TableCell>Carban Credit</TableCell>
                      <TableCell>I2E Point</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {nftData?.length
                      ? nftData?.map((item, i) => (
                          <TableRow
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              navigate(
                                `/manage-nft/view-nft/${item?._id}/${value}`
                              )
                            }
                          >
                            <TableCell align="center">
                              {(page - 1) * 10 + i + 1}
                            </TableCell>
  
                            <TableCell>
                              <figure className="nft_img">
                                <img src={item?.image || ""} alt="" />
                              </figure>
                            </TableCell>
                            <TableCell>{item?.name || ""}</TableCell>
  
                            <TableCell>
                              {item?.sellType === 1 ? "On Bid" : "Private"}
                            </TableCell>
                            <TableCell>{item?.subCategory?.name || ""}</TableCell>
                            <TableCell align="center">
                              {item?.carbonCredit || "0"}
                            </TableCell>
                            <TableCell>{item?.basePrice || "0"}</TableCell>
                            {/* <TableCell>
                            <p>
                              kishore kumar
                              <span className="d_block">
                                kishore@apptunix.com
                              </span>
                              <span className="d_block">9877454492</span>
                            </p>
                          </TableCell> */}
                            <TableCell>
                              {moment(item?.createdAt).format("MMM Do YY")}
                            </TableCell>
                            <TableCell onClick={(e) => e.stopPropagation()}>
                              <Switch
                                {...label}
                                size="small"
                                checked={!item?.isBlocked}
                                onChange={() =>
                                  updateStatus(
                                    item?._id,
                                    !item?.isBlocked,
                                    updateStatusMutation,
                                    setNftData,
                                    nftData
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <ul className="action_btn">
                                <li>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      navigate(
                                        `/manage-nft/view-nft/${item?._id}`
                                      )
                                    }
                                  >
                                    <VisibilityIcon />
                                  </button>
                                </li>
  
                                {/* <li>
                                <button type="button">
                                  <DeleteIcon />
                                </button>
                              </li> */}
                              </ul>
                            </TableCell>
                          </TableRow>
                        ))
                      : !loading &&
                        !nftData?.length && (
                          <TableRow>
                            <TableCell align="center" colSpan={5}>
                              No Data Found
                            </TableCell>
                          </TableRow>
                        )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                setPage={setPage}
                module={nftData}
                page={page}
                onPageChange={onPageChange}
                totalPages={totalPages}
                totalCount={totalCount}
              />
            </CustomTabPanel>
          </div>
        </div>
      </>
    );
}  