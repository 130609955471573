import React, { useState, ChangeEvent } from "react";
import AuthCommon from "../../../../components/auth/AuthCommon";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  CardMedia,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Input,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { CommonBody } from "../../../../types/General";
import * as Yup from "yup";
import { useFormik } from "formik";
import { generateEncryptedKeyBody } from "../../../../utils/crypto";
import {
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  setToStorage,
  successToast,
} from "../../../../helpers";
import { useAppDispatch } from "../../../../hooks/store";
import { useEditProfileMutation } from "../../../../services/auth";
import { setCredentials } from "../../../../reducers/authSlice";
import { isString } from "../../../../utils/validation";
import PhoneInput from "react-phone-input-2";
import { UploadMedia } from "../../../../utils/mediaUpload";
import Loader from "../../../../constants/Loader";
import useAuth from "../../../../hooks/useAuth";

const ProfileSetupContent = () => {
  const user = useAuth();
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [updateProfile, { isLoading }] = useEditProfileMutation();

  const b2bItems = [
    {
      label: "Dashboard",
      key: "1",
      selected: "/dashboard",
    },
    {
      label: "Manage Employees",
      key: "2",
      selected: "/manage-employee",
    },
    {
      label: "Manage Coupon",
      key: "3",
      selected: "/manage-coupons",
    },

    {
      label: "Manage Notification",
      key: "4",
      selected: "/manage-notification",
    },
  ];

  //for image upload
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";

    // Check if a file is selected
    if (file) {
      // Check if the selected file is an image
      if (file.type.startsWith("image/")) {
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          formik.setFieldValue("image", res?.data);
        } else {
          errorToast(res?.message);
        }
      } else {
        // Display an error message for non-image files
        errorToast("Please Try Again");
      }
    }
  };

  // for country picker
  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    formik.setFieldValue(
      "countryCode",
      country?.dialCode.includes("+") ? "" : "+" + country?.dialCode
    );
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: user?.email || "",
      firstName: "",
      lastName: "",
      phone: "",
      countryCode: "+971",
      image: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),
      firstName: Yup.string()
        .required("First name is required")
        .min(2, "Minimum 2 characters are required")
        .max(25, "Maximum 25 character are allowed"),
      lastName: Yup.string()
        .required("Last name is required")
        .max(25, "Maximum 25 character are allowed")
        .min(2, "Minimum 2 characters are required"),

      phone: Yup.string()
        .required("Phone number is required")
        .min(8, "Phone number must be at least 8 digits")
        .max(16, "Phone number must be at least 16 digits"),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);
      let body = {
        email: state?.email,
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        countryCode: formik.values.countryCode,
        phone: formik.values.phone,
        image: formik.values.image,
      };

      try {
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response : any = await updateProfile(encryptedBody).unwrap();
        console.log(response);
        if (response?.statusCode === 200) {
          const token = getFromStorage(STORAGE_KEYS.token);
          console.log(token)
          successToast("Profile Setup successfully");
          setToStorage(STORAGE_KEYS.token, response?.data?.token || "");
          setToStorage(STORAGE_KEYS.USER_ROLE, response?.data?.role || "");
          dispatch(
            setCredentials({
              user: response?.data || null,
              token: token || null,
            })
            );

            if (response?.data?.role === 3) {
              const permissions = response?.data?.permission;
              if (response?.data?.role === 3 && permissions?.length > 0) {
                const firstPermissionLabel = permissions[0]?.label;
                const redirectPath = b2bItems.find(
                  (item) => item?.label === firstPermissionLabel
                )?.selected;
                if (redirectPath) {
                  console.log("redirectPath: ", redirectPath);
                  navigate(redirectPath);
                } else {
                  console.error("Invalid permission label for redirection");
                  // Handle the case where the label doesn’t match any item in the array
                }
              }
            } else {
              // Default redirect for other roles or when no permissions are available
              navigate("/dashboard");
            }
            // navigate("/dashboard");

        } else {
          errorToast(response?.message || "");
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
    },
  });

  return (
    <>
      <Loader isLoad={isLoading} />
      <section className="auth_section">
        <div className="conta_iner">
          <div className="gap_p">
            <AuthCommon />
            <div className="right_side">
              <form onSubmit={formik.handleSubmit} className="form hd_3">
                <h2>Profile Setup</h2>
                <div className="form_control">
                  <div className="profile_flex">
                    {formik.values.image ? (
                      <div className="upload_image">
                        <div className="upload_image_holder">
                          <figure>
                            <CardMedia
                              component="img"
                              image={formik.values.image}
                              alt="photo"
                            />
                          </figure>
                          <CloseIcon
                            onClick={() => {
                              formik.setFieldValue("image", "");
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <label
                        className="upload_image"
                        htmlFor="icon-button-file"
                      >
                        <Input
                          sx={{ display: "none" }}
                          id="icon-button-file"
                          type="file"
                          inputProps={{
                            accept: "image/png,image/jpeg",
                          }}
                          onChange={handleImageUpload}
                        />
                        <span className="upload_image_holder">
                          <figure>
                            <img
                              src="/images/placeholder_user_icon.png"
                              alt=""
                            />
                          </figure>
                          <AddIcon />
                        </span>
                      </label>
                    )}
                    <button className="btn btn_white wallet_btn">
                      <img src="/images/wallet_icon.png" alt="Wallet Icon" />{" "}
                      Connect to Wallet
                    </button>
                  </div>
                </div>
                <div className="form_control">
                  <label> FIRST NAME</label>
                  <TextField
                    hiddenLabel
                    placeholder="First Name"
                    fullWidth
                    type="text"
                    variant="outlined"
                    name="firstName"
                    className="text_field"
                    id="firstName"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName}
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                  />
                </div>
                <div className="form_control">
                  <label>LAST NAME</label>

                  <TextField
                    className="text_field"
                    hiddenLabel
                    placeholder="Last Name"
                    fullWidth
                    type="text"
                    variant="outlined"
                    name="lastName"
                    id="lastName"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastName}
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                  />
                </div>

                <div className="form_control">
                  <label>Email</label>

                  <TextField
                    className="text_field"
                    hiddenLabel
                    placeholder="Email"
                    fullWidth
                    type="email"
                    variant="outlined"
                    name="email"
                    // disabled={state?.email ? true : false}
                    id="email"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </div>
                <div className="form_control">
                  <label className="custom_label">Phone Number</label>
                  <PhoneInput
                    value={formik.values.countryCode + formik.values.phone}
                    country={"us"}
                    placeholder="0 (000) 000-000"
                    enableSearch={true}
                    inputStyle={{ width: "100%" }}
                    onChange={(phone, country) =>
                      handleChangePhone(phone, country)
                    }
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <h6 className="err_msg">
                      {formik.touched.phone && formik.errors.phone}
                    </h6>
                  ) : (
                    ""
                  )}
                </div>

                <div className="form_btn">
                  <Button
                    className="btn btn_primary"
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProfileSetupContent;
