import MainContainer from '../../layout/MainContainer';
import AssetsList from "./feature/assetsList";

const ClaimedAssets = () => {
    return (
        <>
            <MainContainer name="Claimed Assets">
                <AssetsList />
            </MainContainer>
        </>
    );
};

export default ClaimedAssets;