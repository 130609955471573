import { useEffect, useState } from 'react';
import SearchBar from '../../../components/SearchBar';
import { isValidInput } from '../../../utils/validation';
import {
    Box,
    MenuItem,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import Pagination from '../../../components/Pagination';
import FilterComp from '../../../components/filter/filter';
import { Category } from '../../../types/General';
import { Link, useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { useLazyGetBotNFTsQuery } from '../../../services/main';
import { errorToast } from '../../../helpers';
import Loader from '../../../constants/Loader';
import moment from 'moment';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default function ChatBotNFTList() {
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
    const [value, setValue] = useState(0);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [selectedCate, setSelectCate] = useState('');
    const [categories, setCategories] = useState<
        {
            status: string;
            value: string;
        }[]
    >([
        { status: 'confirmed', value: 'Accepted' },
        {
            status: 'pending_confirmation',
            value: 'Pending',
        },
        { status: 'declined', value: 'Rejected' },
    ]);
    const [sorting, setSorting] = useState<
        {
            status: string;
            value: string;
        }[]
    >([
        {
            status: 'asc',
            value: 'Ascending',
        },
        { status: 'des', value: 'Descending' },
    ]);
    const [positionSelect, setPositionSelect] = useState('');
    const [sortSelect, setSortSelect] = useState('1');
    const [page, setPage] = useState<number>(1);
    const [botNFTs, setBotNFTs] = useState([]);
    const [getBotNFTsData, { isLoading }] = useLazyGetBotNFTsQuery();
    const navigate = useNavigate();
    let totalPages = Math.ceil(totalCount / 10);

    const handleChange1 = (event: SelectChangeEvent) => {
        setSelectCate(event.target.value as string);
    };

    const handleChange2 = (event: SelectChangeEvent) => {
        setSortSelect(event.target.value as any);
    };

    const handleChange3 = (event: SelectChangeEvent) => {
        setPositionSelect(event.target.value as string);
    };

    const getBotNFTs = async (
        id: string,
        sort: string,
        page: number,
        size: number,
        search: string
    ) => {
        try {
            const response = await getBotNFTsData({
                id,
                sort,
                page,
                size,
                search,
            }).unwrap();
            if (response?.statusCode === 200) {
                console.log(response?.data, response);
                setLoading(false);
                setBotNFTs(response?.data?.nfts);
                setTotalCount(response?.data?.length);
            }
        } catch (error: any) {
            setLoading(false);
            errorToast(error?.data?.message || '');
        }
    };

    useEffect(() => {
        getBotNFTs('all', 'all', page, 10, 'all');
    }, []);

    useEffect(() => {
        console.log('selected', selectedCate);
        setLoading(true);
        getBotNFTs(
            selectedCate ? selectedCate : 'all',
            positionSelect ? positionSelect : 'all',
            page,
            10,
            debouncedSearchTerm ? debouncedSearchTerm : 'all'
        );
    }, [selectedCate, positionSelect, debouncedSearchTerm, page ]);

    const onPageChange = (newPage: number) => {
        console.log(newPage)
        setPage(newPage);
    };

    // useEffect(()=>{

    // },[positionSelect])

    return (
        <>
            <Loader isLoad={isLoading || loading} />
            <div className="rpt_card mng_role_wrp ">
                <div className="dashboard sec_head fs_22">
                    <h2>Bot Assets</h2>
                    <div className="btn_group1">
                        <SearchBar
                            searchTerm={searchTerm}
                            setDebouncedSearchTerm={setDebouncedSearchTerm}
                            value={searchTerm}
                            onCross={() => setSearchTerm('')}
                            onChange={(val: any) => {
                                if (isValidInput(val.target.value)) {
                                    setSearchTerm(val.target.value);
                                }
                            }}
                        />
                        {/* <div className="add_btn">
                            <button
                                className="btn btn_primary"
                                onClick={() =>
                                    navigate('/manage-platform-nft/add-nft')
                                }
                            >   
                                <EditIcon/>
                            </button>
                        </div> */}
                    </div>
                </div>
                <div className="custom_tabs">
                    {/* <Tabs pointer
                        value={value}
                        onChange={handleChange2}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="basic tabs example"
                        className="custom_tabs_links"
                    >
                        <Tab label="Passive" {...a11yProps(0)} />
                        <Tab label="Minted" {...a11yProps(1)} />
                    </Tabs> */}
                    <CustomTabPanel value={value} index={0}>
                        <div className="filter_sort">
                            <form className="form">
                                <div className="form_control">
                                    <label>Filter by:</label>
                                    <Select
                                        fullWidth
                                        value={selectedCate}
                                        onChange={handleChange1}
                                        inputProps={{
                                            'aria-label': 'Without label',
                                        }}
                                        displayEmpty
                                    >
                                        <MenuItem value="">Select</MenuItem>
                                        {categories.map((item, index) => (
                                            <MenuItem
                                                key={index}
                                                value={item.status}
                                            >
                                                {item?.value}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                                <div className="form_control">
                                    <label>Sort:</label>
                                    {/* <Select
                                        fullWidth
                                        value={sortSelect}
                                        onChange={handleChange2}
                                        inputProps={{
                                            'aria-label': 'Without label',
                                        }}
                                        displayEmpty
                                    >
                                        <MenuItem disabled value={'default'}>
                                            Sort
                                        </MenuItem>
                                        <MenuItem value={1}>By date</MenuItem>
                                        <MenuItem value={2}>
                                            By I2E Points
                                        </MenuItem>
                                        <MenuItem value={3}>
                                            By Carbon Credit Points
                                        </MenuItem>
                                    </Select> */}
                                    <Select
                                        fullWidth
                                        value={positionSelect}
                                        onChange={handleChange3}
                                        displayEmpty
                                    >
                                        <MenuItem value="">Select</MenuItem>
                                        {sorting.map((item, index) => (
                                            <MenuItem
                                                key={index}
                                                value={item.status}
                                            >
                                                {item?.value}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                                {selectedCate ||
                                positionSelect !== '' ||
                                sortSelect !== '1' ? (
                                    <p
                                        className="btn_link c_danger"
                                        onClick={() => {
                                            setSortSelect('1');
                                            setPositionSelect('');
                                            setSelectCate('');
                                        }}
                                    >
                                        Clear filter
                                    </p>
                                ) : null}
                            </form>
                        </div>
                        <TableContainer className="table_container">
                            <Table
                                sx={{ minWidth: 650 }}
                                aria-label="simple table"
                                className="table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">
                                            S.No
                                        </TableCell>
                                        <TableCell>Image</TableCell>
                                        <TableCell>NFT Name</TableCell>
                                        {/* <TableCell>Sub-Category</TableCell> */}
                                        {/* <TableCell>Carban Credit</TableCell> */}
                                        {/* <TableCell>I2E Point</TableCell> */}
                                        <TableCell>Date</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {botNFTs.length >= 1 ? (
                                        botNFTs?.map(
                                            (item: any, index: number) => {
                                                return (
                                                    <TableRow>
                                                        <TableCell align="center">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell>
                                                            <figure className="nft_img">
                                                                <img
                                                                    src={
                                                                        item?.photo_url ||
                                                                        ''
                                                                    }
                                                                    alt=""
                                                                />
                                                            </figure>
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.name}
                                                        </TableCell>
                                                        <TableCell>
                                                            {moment(
                                                                item?.update_at
                                                            ).format(
                                                                'MMM Do YY'
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.status ==
                                                            'pending_confirmation'
                                                                ? 'Pending'
                                                                : item.status ==
                                                                  'declined'
                                                                ? 'Rejected'
                                                                : 'Accepted'}
                                                        </TableCell>
                                                        <TableCell>
                                                            <ul className="action_btn">
                                                                <li>
                                                                    <button
                                                                        style={{
                                                                            backgroundColor:
                                                                                '#56bb6d',
                                                                        }}
                                                                        onClick={() => {
                                                                            navigate(
                                                                                `/manage-chat-bot-nft/update/${item?.id}`
                                                                            );
                                                                        }}
                                                                    >
                                                                        <EditIcon />
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            }
                                        )
                                    ) : (
                                        <TableRow>
                                            <TableCell
                                                align="center"
                                                colSpan={9}
                                            >
                                                No Data Found
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Pagination
                            setPage={setPage}
                            module={botNFTs}
                            page={page}
                            onPageChange={onPageChange}
                            totalPages={totalPages}
                            totalCount={totalCount}
                        />
                    </CustomTabPanel>
                </div>
            </div>
        </>
    );
}
