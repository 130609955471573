// import React from "react";
import MainContainer from '../../layout/MainContainer';
import ChatBotNFTList from "./features/ChatBotNFTList";

const ManageChatBotNFT = () => {
    return (
        <>
            <MainContainer name="Manage Chat-Bot NFTs">
                <ChatBotNFTList />
            </MainContainer>
        </>
    );
};

export default ManageChatBotNFT;
