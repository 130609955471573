import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import { Select, MenuItem, SelectChangeEvent } from '@mui/material';
import 'swiper/css';
import 'swiper/css/navigation';
import {
    useLazyGetUserByIdQuery,
    useLazyGetUserClaimedCouponQuery,
    useLazyGetUserNftQuery,
    useUpdateUserStatusMutation,
    useLazyGetSocialMediaListQuery,
} from '../../../services/main';
import {
    ClaimedCoupon,
    CommonBody,
    Coupon,
    Nft,
    WebUser,
} from '../../../types/General';
import {
    STORAGE_KEYS,
    errorToast,
    getFromStorage,
    successToast,
} from '../../../helpers';
import moment from 'moment';
import { generateEncryptedKeyBody } from '../../../utils/crypto';
import Loader from '../../../constants/Loader';
import CouponCard from '../../managecoupons/feature/CouponCard';
import { setCredentials } from '../../../reducers/authSlice';
import useAuth from '../../../hooks/useAuth';
import { useAppDispatch } from '../../../hooks/store';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const UserDetail = () => {
    const location = useLocation();
    const { state } = location;
    console.log('helo: ', location.pathname.split('/').pop());
    const { id } = useParams();
    const user = useAuth();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [userById, { isLoading }] = useLazyGetUserByIdQuery();
    const [userNftById, userNftByIdData] = useLazyGetUserNftQuery();
    const [userClaimedCouponById, userClaimedCouponByIdData] =
        useLazyGetUserClaimedCouponQuery();
    const [getSocialMediaMethod] = useLazyGetSocialMediaListQuery();

    const [socialMedia, setSocialMedia] = useState([]);
    const [value, setValue] = useState(state?.key === 'byClaimed' ? 2 : 0);
    const [filterType, setfilterType] = useState('');
    const [webUser, setWebUser] = useState<WebUser | null>(null);
    const [claimedCoupon, setClaimedCoupon] = useState<ClaimedCoupon[]>([]);
    const [passiveNft, setPassiveNft] = useState<Nft[]>([]);
    const [mintedNft, setMintedNft] = useState<Nft[]>([]);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleChangeSelect2 = (event: SelectChangeEvent) => {
        setfilterType(event.target.value as string);
    };

    const getUserById = async (id: string) => {
        const token = getFromStorage(STORAGE_KEYS.token);

        dispatch(
            setCredentials({
                user: user,
                token: token,
            })
        );
        try {
            const response = await userById({ id }).unwrap();
            if (response?.statusCode === 200) {
                setWebUser(response?.data);
            }
        } catch (error: any) {
            errorToast(error?.data?.message || '');
        }
    };

    const getUserNftById = async (id: string) => {
        try {
            const response = await userNftById({ id }).unwrap();
            if (response?.statusCode === 200) {
                setPassiveNft(response?.data?.passiveNft || []);
                setMintedNft(response?.data?.mintedNft || []);
            }
        } catch (error: any) {
            errorToast(error?.data?.message || '');
        }
    };

    const getUserClaimedCouponById = async (id: string) => {
        try {
            const response = await userClaimedCouponById({
                id,
                filter: String(filterType),
            }).unwrap();
            if (response?.statusCode === 200) {
                setClaimedCoupon(response?.data?.data || []);
            }
        } catch (error: any) {
            errorToast(error?.data?.message || '');
        }
    };

    const getSocialMediaData = async () => {
        try {
            const res = await getSocialMediaMethod({
                socialMediaId: location.pathname.split('/').pop() as string,
            }).unwrap();
            if (res?.statusCode === 200) {
                console.log(res?.data);
                setSocialMedia(res?.data);
            }
        } catch (error) {}
    };

    useEffect(() => {
        getSocialMediaData();

        // getActiveStep();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        console.log({ socialMedia });
    }, [socialMedia]);

    useEffect(() => {
        if (id) {
            if (id !== 'add' && value === 0) {
                getUserById(id);
            } else if (value === 1) {
                getUserNftById(id);
            } else {
                getUserClaimedCouponById(id);
            }
        }
    }, [value, filterType]);

    return (
        <>
            <Loader
                isLoad={
                    userClaimedCouponByIdData?.isLoading ||
                    isLoading ||
                    userNftByIdData?.isLoading
                }
            />
            <div className="custom_tabs org_detail">
                <div className="bck_btn pos_back ">
                    <button
                        className="btn btn_sm btn_primary"
                        onClick={() => navigate('/manage-users')}
                    >
                        Back
                    </button>
                </div>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="basic tabs example"
                    className="custom_tabs_links"
                >
                    <Tab label="User Detail" {...a11yProps(0)} />
                    <Tab label="User NFT's" {...a11yProps(1)} />
                    <Tab label="User Claimed Coupons" {...a11yProps(2)} />
                </Tabs>
                <CustomTabPanel value={value} index={0}>
                    <div className="form_title fs_16">
                        <h2>General Info</h2>
                        {/* <Switch
              {...label}
              size="small"
              checked={!status}
              onChange={() =>
                updateStatus(String(webUser?._id), !webUser?.isBlocked)
              }
            /> */}
                    </div>
                    <div className="form_info">
                        <ul className="gap_p">
                            <li className="w_100">
                                <p>Profile Picture</p>
                                <figure>
                                    <img
                                        src={
                                            webUser?.image ||
                                            '/images/userDummy.jpeg'
                                        }
                                        alt=""
                                    />
                                </figure>
                            </li>
                            <li>
                                <p>User Name</p>
                                <h6>{webUser?.name || ''}</h6>
                            </li>

                            <li>
                                <p>Date of Birth</p>
                                <h6>
                                    {webUser?.dob
                                        ? moment(webUser?.dob).format('LL')
                                        : ''}
                                </h6>
                            </li>
                            <li>
                                <p>Bio</p>
                                <h6>{webUser?.bio || ''}</h6>
                            </li>
                            {/* <li>
                <p>Social Media Account</p>
                <h6>
                  {webUser?.socialMediaType === 1
                    ? "Facebook"
                    : webUser?.socialMediaType === 2
                    ? "Telegram"
                    : webUser?.socialMediaType === 3
                    ? "Twitter"
                    : "LinkedIn"}
                </h6>
              </li> */}
                            {/* <li>
                <p>Social Media Id</p>
                <h6>{webUser?.socialMediaId || ""}</h6>
              </li> */}
                            <li className="">
                                <p>Contact Number</p>
                                <h6>
                                    {webUser?.contactNo?.length
                                        ? webUser?.contactNo?.map((item) => (
                                              <h6 key={item?.id}>
                                                  {item?.countryCode
                                                      ? (item?.countryCode.includes(
                                                            '+'
                                                        )
                                                            ? ''
                                                            : '+') +
                                                        item?.countryCode
                                                      : null}{' '}
                                                  {item?.phoneNumber || ''}
                                              </h6>
                                          ))
                                        : null}
                                </h6>
                            </li>
                            <li className="">
                                <p>Address</p>
                                {webUser?.address?.length
                                    ? webUser?.address?.map((item) => (
                                          <h6 key={item?.id}>
                                              {item?.address || ''}
                                          </h6>
                                      ))
                                    : null}
                            </li>

                            <li className="w_100">
                                <p>Social Media Account </p>
                                {/* <h6>
                  {webUser?.socialMediaType === 1
                    ? "Facebook"
                    : webUser?.socialMediaType === 2
                    ? "Telegram"
                    : webUser?.socialMediaType === 3
                    ? "Twitter"
                    : "LinkedIn"}
                </h6> */}
                                <TableContainer className="table_container">
                                    <Table
                                        sx={{ minWidth: 650 }}
                                        aria-label="simple table"
                                        className="table"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">
                                                    Social Media Type
                                                </TableCell>
                                                <TableCell align="center">
                                                    Social Media Id
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          
                                                {socialMedia.length > 0 ? (
                                                    socialMedia.map(
                                                        (item: {socialMediaType: number, socialMediaId: string}, index) => {
                                                            const socialMediaType =
                                                                item?.socialMediaType ==
                                                                1
                                                                    ? 'Facebook'
                                                                    : item?.socialMediaType ==
                                                                      2
                                                                    ? 'Telegram'
                                                                    : item?.socialMediaType ===
                                                                      3
                                                                    ? 'Twitter'
                                                                    : item?.socialMediaType ==
                                                                      4
                                                                    ? 'LinkedIn'
                                                                    : 'WhatsApp';
                                                            return (
                                                                <TableRow>
                                                                    <TableCell align="center">{socialMediaType}</TableCell>
                                                                    <TableCell align="center">{item.socialMediaId}</TableCell>
                                                                </TableRow>
                                                            );
                                                        }
                                                    )
                                                ) : (
                                                    <TableRow>
                                                    <TableCell
                                                        align="center"
                                                        colSpan={9}
                                                    >
                                                        No data found
                                                    </TableCell>
                                            </TableRow>
                                                )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </li>
                            {/* <li>
                <p>Social Media Id</p>
                <h6>{webUser?.socialMediaId || ""}</h6>
              </li>    */}
                            {/* <li className="w_100">
                <p>Address</p>
                {webUser?.address?.length
                  ? webUser?.address?.map((item) => (
                      <h6 key={item?.id}>{item?.address || ""}</h6>
                    ))
                  : null}
              </li> */}
                        </ul>
                    </div>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <div className="form_title fs_16">
                        <h2>NFT Details</h2>
                    </div>
                    <div className="nft_dtl mt_30 fs_16">
                        <h3>Passive</h3>
                    </div>
                    <Swiper
                        spaceBetween={20}
                        slidesPerView={3.8}
                        breakpoints={{
                            300: {
                                slidesPerView: 1,
                            },
                            576: {
                                slidesPerView: 2,
                            },
                            768: {
                                slidesPerView: 3,
                            },
                            1025: {
                                slidesPerView: 3,
                            },
                            1350: {
                                slidesPerView: 3.8,
                            },
                        }}
                        autoplay={{
                            delay: 2000,
                            disableOnInteraction: false,
                        }}
                        modules={[Autoplay, Navigation]}
                        loop={true}
                        navigation={false}
                        className="explore_swiper"
                    >
                        {passiveNft?.length ? (
                            passiveNft?.map((item) => (
                                <SwiperSlide>
                                    <div className="expore_box_single">
                                        <figure>
                                            <img
                                                src={item?.image || ''}
                                                alt="explore_attachment_1"
                                            />
                                        </figure>
                                        <h4>
                                            {item?.name || ''}{' '}
                                            {/* <span>3 ETH ${item?.basePrice || 0}</span>{" "} */}
                                        </h4>
                                        <p>By {item?.categoryId?.name || ''}</p>
                                    </div>
                                </SwiperSlide>
                            ))
                        ) : (
                            <div className="no_date_sc hd_4">
                                <figure>
                                    <img
                                        src="/images/noData.png"
                                        alt="noData"
                                    />
                                </figure>
                                <h2>No Data Found</h2>
                            </div>
                        )}
                    </Swiper>

                    <div className="nft_dtl mt_30 fs_16">
                        <h3>Minted</h3>
                    </div>
                    {mintedNft?.length ? (
                        mintedNft?.map((item) => (
                            <SwiperSlide>
                                <div className="expore_box_single">
                                    <figure>
                                        <img
                                            src={
                                                item?.image ||
                                                '/images/purchase_nft2.png'
                                            }
                                            alt="explore_attachment_1"
                                        />
                                    </figure>
                                    <h4>
                                        {item?.name || ''}{' '}
                                        {/* <span>3 ETH ${item?.basePrice || 0}</span>{" "} */}
                                    </h4>
                                    <p>By {item?.categoryId?.name || ''}</p>
                                </div>
                            </SwiperSlide>
                        ))
                    ) : (
                        <div className="no_date_sc hd_4">
                            <figure>
                                <img src="/images/noData.png" alt="noData" />
                            </figure>
                            <h2>No Data Found</h2>
                        </div>
                        // <p className="text_center">No Data Found</p>
                    )}
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <div className="form_title fs_16">
                        <h2>Coupons</h2>
                        <div className="btn_group">
                            <div className="form_control form_control_bg d_flex">
                                <label>Filter by:</label>
                                <Select
                                    fullWidth
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    label="plan-type"
                                    value={filterType}
                                    displayEmpty
                                    inputProps={{
                                        'aria-label': 'Without label',
                                    }}
                                    onChange={handleChangeSelect2}
                                >
                                    <MenuItem value="">All</MenuItem>
                                    <MenuItem value={'1'}>Redeemed</MenuItem>
                                    <MenuItem value={'2'}>Reserved</MenuItem>
                                    <MenuItem value={'3'}>Available</MenuItem>
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="coupon_flx gap_m">
                        {claimedCoupon?.length ? (
                            claimedCoupon?.map((item) => (
                                <div
                                    key={item?._id}
                                    className="coupon_card"
                                    onClick={() =>
                                        navigate(
                                            `/user-claimed-coupon/${id}/${item?._id}`
                                        )
                                    }
                                >
                                    <div className="coupon_card_dtl">
                                        <figure>
                                            <span>Gift Voucher</span>
                                            <img
                                                src="/images/bar_code.svg"
                                                alt=""
                                            />
                                        </figure>
                                        <div className="coupon_dtl fs_21 text_center">
                                            <figure className="uploaded_coupon">
                                                <img
                                                    src={
                                                        item?.couponId?.image ||
                                                        ''
                                                    }
                                                    alt=""
                                                />
                                            </figure>
                                        </div>
                                        <div className="coupon_btn">
                                            <button className="btn btn_primary">
                                                {item?.couponId
                                                    ?.discountType === 1
                                                    ? 'AED '
                                                    : ''}
                                                {item?.couponId
                                                    ?.effectiveValue ||
                                                    '0'}{' '}
                                                {item?.couponId
                                                    ?.discountType === 2
                                                    ? '%'
                                                    : ''}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="coupon_avl">
                                        <p>
                                            {item?.status === 1
                                                ? 'Redeemed'
                                                : item?.status === 2
                                                ? 'Reserved'
                                                : 'Available'}
                                        </p>
                                        <button
                                            className="link"
                                            onClick={() =>
                                                navigate(
                                                    `/manage-coupons/coupon-detail/${item?._id}`
                                                )
                                            }
                                        >
                                            View Details
                                        </button>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="no_date_sc hd_4">
                                <figure>
                                    <img
                                        src="/images/noData.png"
                                        alt="noData"
                                    />
                                </figure>
                                <h2>No Data Found</h2>
                            </div>
                        )}
                    </div>
                </CustomTabPanel>
            </div>
        </>
    );
};

export default UserDetail;
