import React, { PropsWithoutRef, useEffect, useState } from "react";
import {
  Menu,
  Button,
  IconButton,
  Switch,
  Tooltip,
  Modal,
  useMediaQuery,
  Box,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./Layout.scss";
import useAuth from "../hooks/useAuth";
import {
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  removeFromStorage,
  setToStorage,
  successToast,
} from "../helpers";
import {
  getMetaMaskAmount,
  getWeb3Enabled,
  isWeb3Enabled,
  metaMaskAddress,
  setCredentials,
} from "../reducers/authSlice";
import { useAppDispatch, useAppSelector } from "../hooks/store";
import {
  useEditProfileMutation,
  useLazyGetUserQuery,
  usePostLogoutMutation,
} from "../services/auth";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { generateEncryptedKeyBody } from "../utils/crypto";
import { CommonBody } from "../types/General";
import { useLazyGetCmsQuery, usePostCmsMutation } from "../services/common";
import { WebNotSupport } from "../pages/web3NotSupport";
import { generateResponsiveStyle } from "../utils/ModalStyle";
import { BUILD_URL_WEB } from "../helpers/constants/urls";
declare global {
  interface Window {
    ethereum?: any; // This declares the ethereum object on the window
  }
}
interface Props {
  handleSidebarToggle: () => void;
}

function Topbar({ handleSidebarToggle }: PropsWithoutRef<Props>) {
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const style = generateResponsiveStyle();
  const token = getFromStorage(STORAGE_KEYS.token);
  const user = useAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [getUser] = useLazyGetUserQuery();
  const [copied, setCopied] = useState(false);

  const walletAddress = useAppSelector(getMetaMaskAmount);
  const WebEnabled = useAppSelector(getWeb3Enabled);
  const [cmsDetails] = useLazyGetCmsQuery();
  const [addCms, { isLoading }] = usePostCmsMutation();

  const isMobile = useMediaQuery("(max-width:600px)");
  const [isMobileModal, setIsMobileModal] = useState(false);

  useEffect(() => {
    if (window) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);

  const [scroll, setScroll] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
  const open1 = Boolean(anchorEl1);

  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const open2 = Boolean(anchorEl2);

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
  }, []);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const getUserDetails = async () => {
    const token = await getFromStorage(STORAGE_KEYS.token);

    if (token) {
      try {
        const result = await getUser({}).unwrap();
        if (result?.statusCode === 200 && token) {
          dispatch(
            setCredentials({
              user: result?.data,
              token: token,
            })
          );

          if (result?.data?.role === 2){
            setToStorage('companyName', result?.data?.companyName)
            setToStorage ('b2bId', result?.data?._id);
            setToStorage('companyWebSite', result?.data?.companyWebSite)
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token);
    const userData = getFromStorage(STORAGE_KEYS.credentials);

    if (token) {
      dispatch(
        setCredentials({
          token: `${token}`,
          user: JSON.parse(`${userData}`),
        })
      );
      getUserDetails();
    }
  }, []);

  const handleLogoClick = () => {
    if (user && token) {
      navigate("/dashboard");
    } else {
      navigate("/");
    }
  };

  const copyToClipboard = async (address: string) => {
    try {
      await navigator.clipboard.writeText(address);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    } catch (error) {
      console.error("Error copying to clipboard:", error);
    }
  };

  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token);

    if (window.ethereum && user) {
      try {
        window.ethereum.on("accountsChanged", (accounts: any) => {
          console.log("accounts:1222 ", accounts, user?.walletAddress);
          if (accounts?.length) {
            dispatch(
              metaMaskAddress({
                walletAddress: accounts?.[0] || null,
              })
            );
          }
        });
      } catch (err) {
        console.log(err, "kk");
      }
    }
  }, [user]);

  //connect to metamask
  const connectWallet = async () => {
    if (isMobile && typeof window.ethereum === "undefined") {
      setIsMobileModal(true);
    } else {
      if (window.ethereum) {
        // window.ethereum.on("chainChanged", (chainId: any) => {
        //   handleChainChanged(chainId);
        // });
        try {
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          if (accounts.length) {
            setToStorage(STORAGE_KEYS.WalletAddress, accounts[0]);
            dispatch(
              metaMaskAddress({
                walletAddress: accounts?.[0] || null,
              })
            );
            // await handleCheckUser(accounts[0]);
          }
        } catch (err) {
          console.log(err, "kk");
        }
      } else {
        window.open("https://metamask.io/download/", "_blank");
      }
    }
  };

  const updateStatus = async (status: boolean) => {
    let body = {
      isWeb3Enabled: !status,
    };

    console.log(body, "body");

    try {
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await addCms(encryptedBody).unwrap();

      if (response?.statusCode === 200) {
        successToast("Status updated successfully");
        dispatch(
          isWeb3Enabled({
            web3: response?.data?.isWeb3Enabled,
          })
        );
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }

    // try {
    //   let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
    //   const response = await updateProfile(encryptedBody).unwrap();
    //   console.log(response);
    //   if (response?.statusCode === 200) {
    //     const token = getFromStorage(STORAGE_KEYS.token);
    //     successToast("Status updated successfully");
    //     dispatch(
    //       setCredentials({
    //         user: response?.data || null,
    //         token: token || null,
    //       })
    //     );
    //   } else {
    //     errorToast(response?.message || "");
    //   }
    // } catch (error: any) {
    //   errorToast(error?.data?.message || "");
    // }
  };

  const getCmsDetail = async () => {
    const token = getFromStorage(STORAGE_KEYS.token);
    dispatch(
      setCredentials({
        user: user,
        token: token,
      })
    );
    try {
      const res = await cmsDetails({}).unwrap();
      if (res?.statusCode === 200 && res?.data?.email) {
        dispatch(
          isWeb3Enabled({
            web3: res?.data?.isWeb3Enabled,
          })
        );
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (token) {
      getCmsDetail();
    }
  }, []);

  return (
    <>
      <header className={scroll ? "site_header scrolled" : "site_header"}>
        <div className="top_header">
          <div className="conta_iner">
            <div className="d_flex">
              <p>Make waste not wasted. Give waste. Get rewards</p>
              <ul className="social_icons">
                <li>
                  <a href="https://t.me/impact_to_earn" target="_blank">
                    <img src="/images/telegram_icon.svg" alt="Telegram" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/bantgo/"
                    target="_blank"
                  >
                    <img src="/images/linkedin_icon.svg" alt="Linkedin" />
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/BANTgo" target="_blank">
                    <img src="/images/facebook_icon.svg" alt="Facebook" />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/ban_tgo" target="_blank">
                    <img src="/images/twitter_icon.svg" alt="Twitter" />
                  </a>
                </li>
              </ul>
              <button
                className="btn btn_primary"
                onClick={() => window.open(BUILD_URL_WEB, "_blank")}
              >
                Become a Vendor
              </button>
            </div>
          </div>
        </div>
      </header>
      <div className="main_header">
        <div className="conta_iner">
          <nav className="d_flex">
            <a
              href="javacript:void(0)"
              className={`site_hamburgur ${isMenuOpen ? "active" : ""}`}
              onClick={handleSidebarToggle}
            >
              {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
            </a>
            <a
              className="site_logo"
              href="javacript:void(0)"
              onClick={handleLogoClick}
            >
              <figure>
                <img src="/images/impact2earn_logo.png" alt="Logo" />
              </figure>
            </a>
            {/* <ul className="site_menu">
              <li>
                <a href="javacript:void(0)">Home</a>
              </li>
              <li>
                <a href="javacript:void(0)">Marketplace Vendors</a>
              </li>
              <li>
                <a href="javacript:void(0)">Help Support</a>
              </li>
              <li>
                <a href="javacript:void(0)">Join Us</a>
              </li>
            </ul> */}
            <div className="site_action">
              {user && token ? (
                <div className="profile_btn">
                  <Button
                    href="javacript:void(0)"
                    variant="text"
                    aria-controls={open2 ? "basic-menu2" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open2 ? "true" : undefined}
                    onClick={() => navigate("/view-profile")}
                  >
                    <figure>
                      <img
                        src={
                          user?.image ? user?.image : "/images/userDummy.jpeg"
                        }
                        alt=""
                      />
                    </figure>
                    <div>
                      <span>
                        {(user?.firstName || "User") +
                          " " +
                          (user?.lastName || "")}
                      </span>{" "}
                      {user?.role === 2 || user?.role === 3 ? (
                        <h5>{user?.companyName || ""}</h5>
                      ) : null}
                    </div>
                    {/* <ArrowDropDownIcon /> */}
                  </Button>
                  <Menu
                    id="basic-menu2"
                    className="menu_dropdown menu_dropdownUser"
                    anchorEl={anchorEl2}
                    open={open2}
                    onClose={handleClose2}
                    MenuListProps={{
                      "aria-labelledby": "basic-button2",
                    }}
                  >
                    <li>
                      <a href="javacript:void(0)">My Account</a>
                    </li>
                    <li>
                      <a href="javacript:void(0)">Checkout</a>
                    </li>
                    <li>
                      <a href="javacript:void(0)">Cart</a>
                    </li>
                  </Menu>
                </div>
              ) : null}

              {user?.role === 1 ? (
                <Tooltip title="Change Web3 Status">
                  <Switch
                    {...label}
                    size="small"
                    checked={WebEnabled}
                    onChange={() => updateStatus(WebEnabled)}
                  />
                </Tooltip>
              ) : null}

              {user && !walletAddress && token && WebEnabled ? (
                <Button onClick={connectWallet} className="btn_icon ">
                  <img src="/images/metamask_icon.svg" alt="" />
                </Button>
              ) : walletAddress && WebEnabled ? (
                <div className="link_box">
                  <p onClick={() => copyToClipboard(String(walletAddress))}>
                    <span>
                      {String(walletAddress)?.slice(0, 6) +
                        "..." +
                        String(walletAddress)?.slice(-5)}
                    </span>
                    <figure title="Copy">
                      <img src="/images/copy_icon.svg" alt="Icon" />
                    </figure>
                  </p>
                  {copied && (
                    <span style={{ color: "green" }}>Copied to clipboard!</span>
                  )}
                </div>
              ) : undefined}
            </div>
          </nav>
        </div>
      </div>
      {isMobileModal ? (
        <Modal
          open={isMobileModal}
          onClose={() => setIsMobileModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="logout_modal"
        >
          {/* <Box sx={style}> */}
          <div className="modal_body">
            <CloseIcon
              onClick={() => setIsMobileModal(false)}
              className="close"
            />
            <div className="modal_content">
              <WebNotSupport />
            </div>
          </div>
          {/* </Box> */}
        </Modal>
      ) : undefined}
    </>
  );
}

export default Topbar;
