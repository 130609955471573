import React, { useEffect, useState } from 'react';
import MainContainer from '../../layout/MainContainer';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useParams } from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';
import {
    Autocomplete,
    CardMedia,
    FormControlLabel,
    Input,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    TextField,
} from '@mui/material';
import {
    STORAGE_KEYS,
    errorToast,
    getFromStorage,
    successToast,
} from '../../helpers';
import { generateEncryptedKeyBody } from '../../utils/crypto';
import {
    Category,
    Collection,
    CommonBody,
    Coupon,
    WebUser,
} from '../../types/General';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { UploadMedia } from '../../utils/mediaUpload';
import AddIcon from '@mui/icons-material/Add';
import { isNumber, isString } from '../../utils/validation';
import {
    useAddCouponMutation,
    useAddNftMutation,
    useLazyGetAllCollectionQuery,
    useLazyGetCategoryQuery,
    useLazyGetSubCategoryByIdQuery,
} from '../../services/main';

import moment from 'moment';
import useAuth from '../../hooks/useAuth';
import { useAppDispatch } from '../../hooks/store';
import { setCredentials } from '../../reducers/authSlice';
import { Nft } from '../../types/General';
import { useLazyGetUserQuery } from '../../services/auth';
import Loader from '../../constants/Loader';

const AddNft = () => {
    const { id } = useParams();
    const user = useAuth();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [getCollectionMutation] = useLazyGetAllCollectionQuery();
    const [addPlatformNft, { isLoading }] = useAddNftMutation();
    const [getCategory] = useLazyGetCategoryQuery();
    const [getSubcategory] = useLazyGetSubCategoryByIdQuery();

    const [channelSelect, setChannelSelect] = useState('2');
    const [error, setError] = useState<boolean>(false);
    const [status, setStatus] = useState<number>(3);
    const [center, setCenter] = useState('');
    const [selectedCate, setSelectedCate] = useState('');
    const [selectedsubCate, setSelectedSubCate] = useState('');
    const [allCenter, setAllCenter] = useState<Collection[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);
    const [subcategory, setSubCategory] = useState<Category[]>([]);

    const handleChangeCategorySelect = (
        event: React.ChangeEvent<object>,
        value: Category | null
    ) => {
        if (value !== null) {
            setSelectedCate(value?._id);
            setSelectedSubCate('');
            setSubCategory([]);
        } else {
            setSelectedCate(''); // Handle the case where no option is selected
        }
    };

    const handleChangeCenterSelect = (
        event: React.ChangeEvent<object>,
        value: Collection | null
    ) => {
        if (value !== null) {
            setCenter(value?._id);
        } else {
            setCenter(''); // Handle the case where no option is selected
        }
    };

    //for image upload
    const handleImageUpload = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const files = event.target;
        const file = files?.files?.length ? files?.files[0] : '';

        // Check if a file is selected
        if (file) {
            // Check if the selected file is an image
            if (file.type.startsWith('image/')) {
                const res = await UploadMedia(file);
                if (res?.statusCode === 200) {
                    formik.setFieldValue('image', res?.data);
                } else {
                    errorToast(res?.message);
                }
            } else {
                // Display an error message for non-image files
                errorToast('Please Try Again');
            }
        }
    };

    const handleChannelChange = (event: SelectChangeEvent) => {
        setChannelSelect(event.target.value as string);
    };

    const handleChangeSubCategorySelect = (
        event: React.ChangeEvent<object>,
        value: Category | null
    ) => {
        if (value !== null) {
            setSelectedSubCate(value?._id);
        } else {
            setSelectedSubCate('');
        }
    };

    const getAllCategory = async () => {
        const token = getFromStorage(STORAGE_KEYS.token);
        dispatch(
            setCredentials({
                user: user,
                token: token,
            })
        );
        try {
            const res = await getCategory({
                size: 12,
                page: 1,
                query: '',
            }).unwrap();
            if (res?.statusCode === 200) {
                setCategories(res?.data?.category || []);
                setSelectedCate(res?.data?.category?.[0]?._id);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const getAllSubCategory = async (id: string) => {
        if (id) {
            try {
                const res = await getSubcategory({
                    id,
                    size: 12,
                    page: 1,
                    query: '',
                }).unwrap();
                if (res?.statusCode === 200) {
                    setSubCategory(res?.data?.category || []);
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const getAllUser = async () => {
        const token = getFromStorage(STORAGE_KEYS.token);
        dispatch(
            setCredentials({
                user: user,
                token: token,
            })
        );
        try {
            const res = await getCollectionMutation({
                size: 12,
                page: 1,
                query: '',
            }).unwrap();
            if (res?.statusCode === 200) {
                setAllCenter(res?.data?.data || []);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getAllCategory();
    }, []);

    useEffect(() => {
        if (selectedCate) {
            getAllSubCategory(selectedCate);
        }
    }, [selectedCate, selectedCate]);

    useEffect(() => {
        getAllUser();
    }, []);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
            brandName: '',
            description: '',
            image: '',
            carbonCredit: '',
            I2EPoints: '',
            collectionPoint: '',
            audioUrl: '',
            socialMediaId: '',
        },
        validationSchema: Yup.object({
            image: Yup.string().required('Image name is required'),
            audioUrl: Yup.string().matches(
                /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(#[-a-z\d_]*)?$/i,
                'Enter a valid URL format'
            ),
            name: Yup.string()
                .required('This field is required')
                .min(2, 'Minimum 2 characters are required')
                .max(80, 'Maximum 80 character are allowed'),

            brandName: Yup.string()
                .required('This field is required')
                .min(2, 'Minimum 2 characters are required')
                .max(80, 'Maximum 80 character are allowed'),
            carbonCredit: Yup.string().required('This field is required'),
            I2EPoints: Yup.string().required('This field is required'),
            socialMediaId: Yup.string().required('This field is required'),
            description: Yup.string()
                .required('This field is required')
                .min(2, 'Minimum 2 characters are required')
                .max(3000, 'Maximum 3000 character are allowed'),
        }),
        onSubmit: async () => {
            formik.setSubmitting(true);

            if (!selectedCate || !selectedsubCate) {
                return;
            }

            let body = {
                image: formik.values.image,
                description: formik.values.description,
                name: formik.values.name,
                brandName: formik.values.brandName,
                basePrice: Number(formik.values.I2EPoints),
                categoryId: selectedCate,
                subCategoryId: selectedsubCate,
                status: status,
                carbonCredit: Number(formik.values.carbonCredit),
                channelName: channelSelect,
                collectionPoint: center,
                isApproved: true,
                ...(formik.values.audioUrl
                    ? { audioUrl: formik.values.audioUrl }
                    : {}),
                nftType: 1,
                passiveNftType: 1,
                socialMediaType: 2,
                socialMediaId: formik.values.socialMediaId,
            };
            try {
                let encryptedBody = generateEncryptedKeyBody(
                    body
                ) as CommonBody;

                const response = await addPlatformNft(encryptedBody).unwrap();
                if (response?.statusCode === 200) {
                    successToast('NFT Added Successfully');
                    formik.resetForm();
                    navigate('/manage-platform-nft');
                } else {
                    errorToast(response?.message || '');
                }
            } catch (error: any) {
                errorToast(error?.data?.message || '');
            }
        },
    });

    return (
        <MainContainer>
            <Loader isLoad={isLoading} />
            <div className="coupon coupon_dtl_page">
                <div className="sec_head">
                    <h2>{id ? 'Edit' : 'Add'} NFT</h2>
                    <div className="btn_group">
                        <div
                            className="btn btn_sm btn_primary"
                            onClick={() => navigate('/manage-platform-nft')}
                        >
                            Back
                        </div>
                    </div>
                </div>
                <div className="form_group gap_m">
                    <div className="form_control form_control_bg  w_50">
                        <label>NFT Image (dimensions 498×540)</label>
                        {formik.values.image ? (
                            <div className="upload_image  full_img">
                                <div className="upload_image_holder">
                                    <figure>
                                        <CardMedia
                                            component="img"
                                            image={formik.values.image}
                                            alt="photo"
                                        />
                                    </figure>
                                    <CloseIcon
                                        onClick={() => {
                                            formik.setFieldValue('image', '');
                                        }}
                                    />
                                </div>
                            </div>
                        ) : (
                            <label
                                className="upload_image full_img"
                                htmlFor="icon-button-file"
                            >
                                <Input
                                    sx={{ display: 'none' }}
                                    id="icon-button-file"
                                    type="file"
                                    inputProps={{
                                        accept: 'image/png,image/jpeg',
                                    }}
                                    onChange={handleImageUpload}
                                />
                                <span className="upload_image_holder">
                                    <figure>
                                        <img
                                            src="/images/placeholder_gallery.jpeg"
                                            alt=""
                                        />
                                    </figure>
                                    <AddIcon />
                                </span>

                                {!formik.values.image && error ? (
                                    <h6 className="err_msg">
                                        This field is required
                                    </h6>
                                ) : (
                                    ''
                                )}
                            </label>
                        )}
                    </div>
                    <div className="coupon_status sec_head  ">
                        <h2> Status</h2>
                        <div className="cpn_status_check">
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"
                                value={status}
                            >
                                <FormControlLabel
                                    className="radio_check"
                                    value={1}
                                    control={<Radio />}
                                    label="Draft"
                                    onChange={() => setStatus(1)}
                                />
                                <FormControlLabel
                                    className="radio_check"
                                    value={4}
                                    control={<Radio />}
                                    label="Declined"
                                    onChange={() => setStatus(4)}
                                />
                                <FormControlLabel
                                    className="radio_check"
                                    value={3}
                                    control={<Radio />}
                                    label="Accepted"
                                    onChange={() => setStatus(3)}
                                />
                                <FormControlLabel
                                    className="radio_check"
                                    value={2}
                                    control={<Radio />}
                                    label="Verification Required"
                                    onChange={() => setStatus(2)}
                                />
                            </RadioGroup>
                        </div>
                    </div>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div className="coupon_frm_wrp">
                        <div className="form_group gap_m">
                            <div className="form_control form_control_bg w_50">
                                <label>Name</label>
                                <TextField
                                    hiddenLabel
                                    placeholder="Name"
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    className="text_field"
                                    name="name"
                                    id="name"
                                    onChange={(val) => {
                                        if (
                                            val.target.value === ' ' ||
                                            val.target.value === '.'
                                        ) {
                                        } else if (isString(val.target.value)) {
                                            formik.handleChange(val);
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.name}
                                    helperText={
                                        formik.touched.name &&
                                        formik.errors.name
                                    }
                                />
                            </div>

                            <div className="form_control form_control_bg w_50">
                                <label>Brand Name</label>
                                <TextField
                                    hiddenLabel
                                    placeholder="Brand Name"
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    className="text_field"
                                    name="brandName"
                                    id="brandName"
                                    onChange={(val) => {
                                        if (
                                            val.target.value === ' ' ||
                                            val.target.value === '.'
                                        ) {
                                        } else if (isString(val.target.value)) {
                                            formik.handleChange(val);
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.brandName}
                                    helperText={
                                        formik.touched.brandName &&
                                        formik.errors.brandName
                                    }
                                />
                            </div>

                            <div className="form_control form_control_bg w_50">
                                <label>Channel Name</label>
                                <Select
                                    fullWidth
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    label="social-account"
                                    value={channelSelect}
                                    onChange={handleChannelChange}
                                >
                                    <MenuItem value={2}>Telegram</MenuItem>
                                    <MenuItem value={1}>Facebook</MenuItem>
                                    <MenuItem value={3}>Twitter</MenuItem>
                                    <MenuItem value={4}>LinedIn</MenuItem>
                                    <MenuItem value={5}>WhatsApp</MenuItem>
                                </Select>
                            </div>
                            <div className="form_control form_control_bg w_50">
                                <label>Assign To </label>
                                <TextField
                                    hiddenLabel
                                    placeholder="Enter Social Media Id"
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    className="text_field"
                                    name="socialMediaId"
                                    id="socialMediaId"
                                    onChange={(val) => {
                                        if (
                                            val.target.value === ' ' ||
                                            val.target.value === '.' ||
                                            val.target.value?.includes('@')
                                        ) {
                                        } else {
                                            formik.handleChange(val);
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.socialMediaId}
                                    helperText={
                                        formik.touched.socialMediaId &&
                                        formik.errors.socialMediaId
                                    }
                                />
                            </div>
                            <div className="form_control form_control_bg w_50">
                                <label>Carbon Credits</label>
                                <TextField
                                    hiddenLabel
                                    placeholder="Carbon Credits"
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    className="text_field"
                                    name="carbonCredit"
                                    id="carbonCredit"
                                    onChange={(val) => {
                                        if (
                                            val.target.value === ' ' ||
                                            val.target.value === '.'
                                        ) {
                                        } else if (isNumber(val.target.value)) {
                                            formik.handleChange(val);
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.carbonCredit}
                                    helperText={
                                        formik.touched.carbonCredit &&
                                        formik.errors.carbonCredit
                                    }
                                />
                            </div>

                            <div className="form_control form_control_bg w_50">
                                <label>I2E Points</label>
                                <TextField
                                    hiddenLabel
                                    placeholder="I2E Points"
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    className="text_field"
                                    name="I2EPoints"
                                    id="I2EPoints"
                                    onChange={(val) => {
                                        if (
                                            val.target.value === ' ' ||
                                            val.target.value === '.'
                                        ) {
                                        } else if (isNumber(val.target.value)) {
                                            formik.handleChange(val);
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.I2EPoints}
                                    helperText={
                                        formik.touched.I2EPoints &&
                                        formik.errors.I2EPoints
                                    }
                                />
                            </div>

                            <div className="form_control form_control_bg w_50">
                                <label>Collection Center</label>
                                <Autocomplete
                                    className="prfl_autocmplt"
                                    disablePortal
                                    id="combo-box-demo"
                                    onChange={handleChangeCenterSelect}
                                    options={allCenter?.length ? allCenter : []}
                                    getOptionLabel={(option) =>
                                        `${option?.name} - ${option?.address}`
                                    }
                                    sx={{ width: 'auto' }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Collection Center"
                                        />
                                    )}
                                />
                                {!center && error ? (
                                    <h6 className="err_msg">
                                        This field is required
                                    </h6>
                                ) : (
                                    ''
                                )}
                                {/* <TextField
                  hiddenLabel
                  placeholder="Collection Points"
                  fullWidth
                  type="text"
                  variant="outlined"
                  className="text_field"
                  name="collectionPoint"
                  id="collectionPoint"
                  onChange={(val) => {
                    if (val.target.value === " " || val.target.value === ".") {
                    } else if (isNumber(val.target.value)) {
                      formik.handleChange(val);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.collectionPoint}
                  helperText={
                    formik.touched.collectionPoint &&
                    formik.errors.collectionPoint
                  }
                /> */}
                            </div>
                            <div style={{ display: 'none' }} className="form_control form_control_bg w_50">
                                <label>Select Category</label>
                                <Autocomplete
                                    value={
                                        categories.find(
                                            (category) =>
                                                category._id === selectedCate
                                        ) || null
                                    }
                                    disabled={true}
                                    className="prfl_autocmplt"
                                    disablePortal
                                    id="combo-box-demo"
                                    onChange={handleChangeCategorySelect}
                                    options={
                                        categories?.length ? categories : []
                                    }
                                    getOptionLabel={(option) => option?.name}
                                    sx={{ width: 'auto' }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Categories"
                                        />
                                    )}
                                />
                                {!selectedCate && error ? (
                                    <h6 className="err_msg">
                                        This field is required
                                    </h6>
                                ) : (
                                    ''
                                )}
                            </div>

                            <div className="form_control form_control_bg w_50">
                                <label>Select Category</label>
                                <Autocomplete
                                    className="prfl_autocmplt"
                                    disablePortal
                                    id="combo-box-demo"
                                    onChange={handleChangeSubCategorySelect}
                                    options={
                                        subcategory?.length ? subcategory : []
                                    }
                                    getOptionLabel={(option) => option?.name}
                                    sx={{ width: 'auto' }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Categories"
                                        />
                                    )}
                                />
                                {!selectedsubCate && error ? (
                                    <h6 className="err_msg">
                                        This field is required
                                    </h6>
                                ) : (
                                    ''
                                )}
                            </div>

                            <div style={{ display: 'none' }} className="form_control form_control_bg w_50">
                                <label>Audio Url</label>
                                <TextField
                                    hiddenLabel
                                    placeholder="Audio Url"
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    className="text_field"
                                    name="audioUrl"
                                    id="audioUrl"
                                    onChange={(val) => {
                                        if (
                                            val.target.value === ' ' ||
                                            val.target.value === '.'
                                        ) {
                                        } else {
                                            formik.handleChange(val);
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.audioUrl}
                                    helperText={
                                        formik.touched.audioUrl &&
                                        formik.errors.audioUrl
                                    }
                                />
                            </div>

                            <div className="form_control form_control_bg w_100">
                                <label>Short Note</label>
                                <TextField
                                    multiline
                                    maxRows={4}
                                    minRows={4}
                                    hiddenLabel
                                    placeholder="Enter Short Note"
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    className="text_field"
                                    name="description"
                                    id="description"
                                    onChange={(val) => {
                                        if (
                                            val.target.value === ' ' ||
                                            val.target.value === '.'
                                        ) {
                                        } else if (isString(val.target.value)) {
                                            formik.handleChange(val);
                                        }
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.description}
                                    helperText={
                                        formik.touched.description &&
                                        formik.errors.description
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="submit_btn">
                        <button
                            className="btn btn_primary"
                            onClick={() => setError(true)}
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </MainContainer>
    );
};

export default AddNft;
