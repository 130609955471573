import React, { useState, ChangeEvent, useEffect } from "react";
import {
  Box,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { CommonBody } from "../../../types/General";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { errorToast, getFromStorage, successToast } from "../../../helpers";
import {
  useAddEmployeeMutation,
  useLazyDeleteEmployeeByIdQuery,
  useLazyGetEmployeeByIdQuery,
  useUpdateEmployeeMutation,
} from "../../../services/organisation";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../constants/Loader";
import { isString } from "../../../utils/validation";
import { Employee } from "../../../types/User";
import WarnModal from "../../../components/modals/WarnModal";

interface DataType {
  key?: string;
  label: string;
  isAdd: boolean;
  isView: boolean;
  isDelete?: boolean;
  disabled?: boolean;
}

export default function EmployeeDetail() {
  const { id } = useParams();
  const { value } = useParams();
  console.log("value: ws", value);
  const navigate = useNavigate();
  const [addEmployee, { isLoading }] = useAddEmployeeMutation();
  const [updateEmployee, updateEmployeeData] = useUpdateEmployeeMutation();
  const [employeeById] = useLazyGetEmployeeByIdQuery();
  const [deleteById, deleteByIdData] = useLazyDeleteEmployeeByIdQuery();

  const [employee, setEmployee] = useState<Employee | null>(null);
  const [newData, setNewData] = useState<DataType[]>([] as any);
  const [open, setOpen] = useState(false);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: employee?.email || "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);

      permissions?.length > 0 &&
        permissions.map((element) => {
          const obj = {
            label: element?.label,
            isAdd: element.isAdd,
            isView: element.isView,
            isDelete: element.isDelete,
          };
          if (obj) {
            newData?.push(obj);
            setNewData([...newData]);
          }
        });

      let body = {
        email: formik.values.email,
        companyName: getFromStorage('companyName'),
        b2bId: getFromStorage('b2bId'),
        companyWebSite: getFromStorage('companyWebSite'),
        permission: newData.filter((element) => {
          return (
            element.isView === true ||
            element.isAdd === true ||
            element.isDelete === true
          );
        }),
      };
      console.log(body, "bodyf");

      if (!body?.permission?.length) {
        errorToast("Please assign permissions to employee");
        return;
      }

      if (id && employee) {
        try {
          let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
          const response = await updateEmployee({
            body: encryptedBody,
            id: value == "0" ? employee?.b2bEmployeeId : id,
          }).unwrap();
          if (response?.statusCode === 200) {
            successToast("Employee Updated Successfully");
            formik.resetForm();
            navigate("/manage-employee");
          } else {
            errorToast(response?.message || "");
          }
        } catch (error: any) {
          errorToast(error?.data?.message || "");
        }
      } else {
        try {
          let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

          const response = await addEmployee(encryptedBody).unwrap();
          if (response?.statusCode === 200) {
            successToast("Employee Added Successfully");
            formik.resetForm();
            navigate(-1);
          } else {
            errorToast(response?.message || "");
          }
        } catch (error: any) {
          errorToast(error?.data?.message || "");
        }
      }
    },
  });
  const [permissions, setPermissions] = useState<DataType[]>([
    {
      key: "1",
      label: "Dashboard",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      key: "2",
      label: "Manage Employees",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      key: "3",
      label: "Manage Coupon",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },

    // {
    //   key: "4",
    //   label: "Manage Notification",
    //   isAdd: false,
    //   isView: false,
    //   isDelete: false,
    //   disabled: false,
    // },
  ]);

  const onSelectChange = (key: any, type: string) => {
    let idx = permissions.findIndex((obj) => obj.label === key.label);
    if (type === "addEdit") {
      permissions[idx].isAdd = !key.isAdd;
      if (key.isAdd) {
        permissions[idx].isView = true;
      } else {
        permissions[idx].isView = false;
      }
    } else if (type === "isview") {
      permissions[idx].isView = !key.isView;
    } else {
      permissions[idx].isDelete = !key.isDelete;
      if (key.isDelete) {
        permissions[idx].isView = true;
        permissions[idx].isAdd = true;
      } else {
        permissions[idx].isView = false;
        permissions[idx].isAdd = false;
      }
    }
    setPermissions([...permissions]);
  };

  const handleDelete = async () => {
    if (id) {
      try {
        const response = await deleteById({ id }).unwrap();
        if (response?.statusCode === 200) {
          successToast("Deleted Successfully");
          navigate("/manage-employee");
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
    }
  };

  const getEmployeeById = async (id: string) => {
    try {
      const response = await employeeById({
        id,
        value,
      }).unwrap();
      if (response?.statusCode === 200) {
        setEmployee(response?.data);
        const modifiedArr2 = permissions.map((item) => {
          const foundItem = response?.data?.permission?.find(
            (i) => i?.label === item.label
          );
          if (foundItem) {
            return {
              ...item,
              isView: foundItem.isView,
              isAdd: foundItem.isAdd,
              isDelete: foundItem.isDelete,
            };
          }
          return item;
        });
        setPermissions(modifiedArr2);
      }
    } catch (error: any) {
      console.log(error, "errror");
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      getEmployeeById(id);
    }
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="form">
        <Loader
          isLoad={
            isLoading ||
            updateEmployeeData?.isLoading ||
            deleteByIdData?.isLoading
          }
        />
        <div className="dashboard sec_head fs_22 d_flx">
          <h2>{id ? "Edit" : "Add"} Employees</h2>
          <div className="btn_group bck_btn">
            <button className="btn btn_sm btn_primary">Save Details</button>
            {id ? (
              <h3
                className="btn btn_sm  btn_danger"
                onClick={() => setOpen(true)}
              >
                Delete Employee
              </h3>
            ) : null}

            <button
              className="btn btn_sm  btn_primary"
              onClick={() => navigate(`/manage-employee/view/${id}/${value}`)}
            >
              Back
            </button>
          </div>
        </div>

        <div className="edit_info org_form">
          <div className="form_title fs_16">
            <h2>General Info</h2>
          </div>
          <div className="form_group gap_m">
            <div className="form_control form_control_bg w_50">
              <label>Email</label>
              <TextField
                hiddenLabel
                placeholder="Enter email address"
                fullWidth
                type="email"
                variant="outlined"
                name="email"
                className="text_field"
                id="email"
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                helperText={formik.touched.email && formik.errors.email}
              />
            </div>
          </div>

          <div className="form_title fs_16 mt_40">
            <h2>Roles & Permissions</h2>
          </div>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              className="table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Module</TableCell>
                  <TableCell>View</TableCell>
                  <TableCell>Add/Edit</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {permissions?.length
                  ? permissions?.map((item) => (
                      <TableRow key={item?.key}>
                        <TableCell>{item?.label}</TableCell>
                        <TableCell>
                          <Checkbox
                            {...label}
                            onChange={() => onSelectChange(item, "isview")}
                            checked={item?.isView || false}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            {...label}
                            onChange={() => onSelectChange(item, "addEdit")}
                            checked={item?.isAdd || false}
                          />
                        </TableCell>

                        <TableCell>
                          <Checkbox
                            {...label}
                            onChange={() => onSelectChange(item, "isdel")}
                            checked={item?.isDelete || false}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <WarnModal
          setOpen={setOpen}
          open={open}
          name="employee"
          handleDelete={handleDelete}
        />
      </form>
    </>
  );
}
