import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useLazyGetCouponByIdQuery,
  useLazyGetCouponNftQuery,
} from "../../services/main";
import { Coupon, Nft } from "../../types/General";
import {
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../../helpers";
import moment from "moment";
import { User } from "../../types/User";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLazyDeleteCouponByIdQuery } from "../../services/main";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import WarnModal from "../../components/modals/WarnModal";
import useAuth from "../../hooks/useAuth";
import { useAppDispatch } from "../../hooks/store";
import { setCredentials } from "../../reducers/authSlice";
import Loader from "../../constants/Loader";
import VisibilityIcon from "@mui/icons-material/Visibility";

const CouponDetail = () => {
  const { id } = useParams();
  const user = useAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { state } = location;

  const [couponById] = useLazyGetCouponByIdQuery();
  const [couponNft] = useLazyGetCouponNftQuery();
  const [deleteById, deleteByIdData] = useLazyDeleteCouponByIdQuery();

  const [open, setOpen] = useState(false);
  const [coupon, setCoupon] = useState<Coupon | null>(null);
  const [nft, setNft] = useState<Nft[] | null>(null);

  const handleDelete = async () => {
    if (id) {
      try {
        const response = await deleteById({ id }).unwrap();
        if (response?.statusCode === 200) {
          successToast("Deleted Successfully");
          navigate("/manage-coupons");
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
    }
  };

  const getCouponByById = async (id: string) => {
    const token = getFromStorage(STORAGE_KEYS.token);

    dispatch(
      setCredentials({
        user: user,
        token: token,
      })
    );

    try {
      const response = await couponById({ id }).unwrap();
      if (response?.statusCode === 200) {
        setCoupon(response?.data);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const getCouponNftById = async (id: string) => {
    const token = getFromStorage(STORAGE_KEYS.token);

    dispatch(
      setCredentials({
        user: user,
        token: token,
      })
    );

    try {
      const response = await couponNft({ id }).unwrap();
      if (response?.statusCode === 200) {
        console.log("response: 12", response);
        setNft(response?.data?.couponNft || []);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      getCouponByById(id);
      getCouponNftById(id);
    }
  }, []);

  return (
    <MainContainer name="Manage Employees">
      <Loader isLoad={deleteByIdData?.isLoading} />
      <div className="coupon coupon_dtl_page ">
        <div className="sec_head">
          <h2>Coupon Detail</h2>
          <div className="btn_group">
            <div
              className="btn btn_sm btn_primary"
              onClick={() =>
                state?.tabKey
                  ? navigate(`/manage-organization/view/${state?.tabKey}`, {
                      state: { key: "byCoupon" },
                    })
                  : navigate("/manage-coupons")
              }
            >
              Back
            </div>
          </div>
        </div>
        <div className="form_title fs_16">
          <h2>
            Coupon Info
            {coupon?.nftCount === 0 ? (
              <>
                <figure
                  className="pointer"
                  onClick={() =>
                    navigate(`/manage-coupons/edit/${coupon?._id}`)
                  }
                >
                  <img src="/images/edit_icon.svg" alt="" />
                </figure>
                <IconButton
                  className="pos_delete"
                  onClick={() => setOpen(true)}
                >
                  <DeleteIcon />
                </IconButton>
              </>
            ) : null}
          </h2>
        </div>
        <div className="form_info">
          <ul className="gap_p">
            <li className="w_100">
              <p>Coupon Image</p>
              <div className="coupon_card">
                <div className="coupon_card_dtl">
                  <figure>
                    <span>Gift Voucher</span>
                    <img src="/images/bar_code.svg" alt="" />
                  </figure>
                  <div className="coupon_dtl fs_28 text_center">
                    <figure className="uploaded_coupon">
                      <img
                        src={
                          user?.role === 1 && !coupon?.image
                            ? coupon?.assignedTo?.[0]?.image
                            : (user?.role === 2 || user?.role === 3) 
                            ? coupon?.image || coupon?.assignedTo?.[0]?.image
                            : ''
                        }
                        alt=""
                      />
                    </figure>
                  </div>
                  <div className="coupon_btn">
                    <button className="btn btn_primary">
                      {coupon?.discountType === 1 ? "AED " : ""}
                      {coupon?.effectiveValue || "0"}{" "}
                      {coupon?.discountType === 2 ? "%" : ""}
                    </button>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <p>Coupon Code</p>
              <h6>{coupon?.code || ""}</h6>
            </li>
            <li>
              <p>Coupon Status</p>
              <h6>
                {coupon?.status === 1
                  ? "Redeemed"
                  : coupon?.status === 2
                  ? "Reserved"
                  : "Available"}
              </h6>
            </li>
            <li>
              <p>Value</p>
              <h6>
                {coupon?.discountType === 1 ? "AED " : ""}
                {coupon?.effectiveValue || "0"}{" "}
                {coupon?.discountType === 2 ? "%" : ""}
              </h6>
            </li>
            <li>
              <p>Start Date</p>
              <h6>{moment(coupon?.startDate).format("LL")}</h6>
            </li>
            {coupon?.endDate ? (
              <li>
                <p>End Date</p>
                <h6>
                  {coupon?.endDate ? moment(coupon?.endDate).format("LL") : ""}
                </h6>
              </li>
            ) : null}

            <li>
              <p>Coupon Type</p>
              <h6>{coupon?.couponType === 2 ? "Direct" : "Indirect"}</h6>
            </li>
            <li>
              <p>Total Coupon </p>
              <h6>{coupon?.quantity || 0}</h6>
            </li>
            <li>
              <p>Coupon Remaining</p>
              <h6>{coupon?.quantityLeft || 0}</h6>
            </li>
            {user?.role === 1 ? (
              <li>
                <p>Assign To</p>
                {coupon?.assignedTo?.length
                  ? coupon?.assignedTo?.map((item: User, i) => (
                      <h6 key={i}>
                        {item?.companyName || ""}{" "}
                        <span>({item?.companyWebSite || ""})</span>
                      </h6>
                    ))
                  : null}
              </li>
            ) : null}

            <li className="w_100">
              <p>Short Note</p>
              <h6>{coupon?.description || ""}</h6>
            </li>
          </ul>
        </div>
        <div className="form_title fs_16 mt_40">
          <h2>NFT Detail</h2>
        </div>
        <TableContainer className="table_container">
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className="table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">S.No</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>NFT Name</TableCell>
                <TableCell>Carban Credit</TableCell>
                <TableCell>I2E Point</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {nft?.length ? (
                nft?.map((item, i) => (
                  <TableRow
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate(`/manage-nft/view-nft/${item?._id}/${1}`, {
                        state: { couponId: id },
                      })
                    }
                  >
                    <TableCell align="center">{i + 1}</TableCell>

                    <TableCell>
                      <figure className="nft_img">
                        <img src={item?.image || ""} alt="" />
                      </figure>
                    </TableCell>
                    <TableCell>{item?.name || ""}</TableCell>
                    <TableCell align="center">
                      {item?.carbonCredit || "0"}
                    </TableCell>
                    <TableCell>{item?.basePrice || "0"}</TableCell>
                    {/* <TableCell>
                          <p>
                            kishore kumar
                            <span className="d_block">
                              kishore@apptunix.com
                            </span>
                            <span className="d_block">9877454492</span>
                          </p>
                        </TableCell> */}
                    <TableCell>
                      {moment(item?.createdAt).format("MMM Do YY")}
                    </TableCell>
                    <TableCell>
                      <ul className="action_btn">
                        <li>
                          <button
                            type="button"
                            onClick={() =>
                              navigate(
                                `/manage-nft/view-nft/${item?._id}/${1}`,
                                {
                                  state: { couponId: id },
                                }
                              )
                            }
                          >
                            <VisibilityIcon />
                          </button>
                        </li>

                        {/* <li>
                              <button type="button">
                                <DeleteIcon />
                              </button>
                            </li> */}
                      </ul>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={5}>
                    No Data Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <div className="form_info">
          <ul className="gap_p">
            <li className="w_100">
              <p>NFT Image</p>
              <div className="prfl_pic w_30">
                <div className="upload_image">
                  <div className="upload_image_holder">
                    <figure>
                      <img
                        src={nft?.image || "/images/explore_attachment_3.jpg"}
                        alt=""
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <p>NFT Name</p>
              <h6>{nft?.name || ""}</h6>
            </li>
            <li>
              <p>Brand Name</p>
              <h6>{nft?.brandName || ""}</h6>
            </li>
            <li>
              <p>Carban Credit</p>
              <h6>{nft?.carbonCredit || ""}</h6>
            </li>
            <li className="w_100">
              <p>Description</p>
              <h6>{nft?.description || ""}</h6>
            </li>
            {nft?.userId ? (
              <>
                <li>
                  <p>User Name</p>
                  <h6>{"Kishore" || ""}</h6>
                </li>
                <li>
                  <p>User Contact Number</p>
                  <h6>9877454492</h6>
                </li>
                <li>
                  <p>User Email</p>
                  <h6>kishore@apptunix.com</h6>
                </li>
              </>
            ) : null}
          </ul>
        </div> */}
      </div>
      <WarnModal
        setOpen={setOpen}
        open={open}
        name="coupon"
        handleDelete={handleDelete}
      />
    </MainContainer>
  );
};

export default CouponDetail;
