import React from "react";
import { useNavigate } from "react-router-dom";
import { Coupon } from "../../../types/General";
import useAuth from "../../../hooks/useAuth";

type props = {
  item: Coupon;
  couponTab?: number;
  tabKey?: string;
};

export default function CouponCard({ item, couponTab, tabKey }: props) {
  const navigate = useNavigate();
  const user = useAuth();

  return (
    <>
      <div
        className="coupon_card"
        onClick={() =>
          navigate(`/manage-coupons/coupon-detail/${item?._id}`, {
            state: { couponTab, tabKey },
          })
        }
      >
        <div className="coupon_card_dtl">
          <figure>
            <span>Gift Voucher</span>
            <img src="/images/bar_code.svg" alt="" />
          </figure>
          {/* <p>Gift Voucher</p> */}
          <div className="coupon_dtl fs_21 text_center">
            <figure className="uploaded_coupon">
              <img
                src={
                  user?.role === 1 && !item?.image
                    ? item?.assignedTo?.[0]?.image
                    : (user?.role === 2 || user?.role === 3) 
                    ? item?.image || item?.assignedTo?.[0]?.image
                    : item?.image
                }
              />
            </figure>
          </div>
          <div className="coupon_btn">
            <button className="btn btn_primary">
              {item?.discountType === 1 ? "AED " : ""}
              {item?.effectiveValue || "0"}{" "}
              {item?.discountType === 2 ? "%" : ""}
            </button>
          </div>
        </div>

        <div className="coupon_avl">
          <p>
            {item?.status === 1
              ? "Redeemed"
              : item?.status === 2
              ? "Reserved"
              : "Available"}
          </p>
          <button
            className="link"
            onClick={() =>
              navigate(`/manage-coupons/coupon-detail/${item?._id}`)
            }
          >
            View Details
          </button>
        </div>
      </div>
    </>
  );
}
