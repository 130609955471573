// @ts-nocheck
import {
    Autocomplete,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import MainContainer from '../../layout/MainContainer';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DoneIcon from '@mui/icons-material/Done';
import {
    useLazyGetAdminNftByIdQuery,
    useLazyGetUsersQuery,
    useRedeemCouponMutation,
} from '../../services/main';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { CommonBody, Nft, WebUser } from '../../types/General';
import {
    STORAGE_KEYS,
    errorToast,
    getFromStorage,
    successToast,
} from '../../helpers';
import Loader from '../../constants/Loader';
import { getWeb3Enabled, setCredentials } from '../../reducers/authSlice';
import useAuth from '../../hooks/useAuth';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { generateEncryptedKeyBody } from '../../utils/crypto';
import { IconButton } from '@mui/material';
import {
    useGiftNftMutation,
    useHandleBidActionMutation,
    useMintNftMutation,
    useSetNftOnBidMutation,
} from '../../services/nft';
import Web3 from 'web3';
import {
    ABI,
    Common721AAddress,
    Common721ABI,
    CommonMarketAddress,
} from '../../utils/configration';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function UpdateAsset() {
    const web3 = new Web3(window.ethereum);
    const CommonMarketContract = new web3.eth.Contract(
        ABI,
        CommonMarketAddress
    );

    const Common721Contract = new web3.eth.Contract(
        Common721ABI,
        Common721AAddress
    );

    const location = useLocation();
    const { state } = location;
    const { id } = useParams();
    const { valueNum } = useParams();
    const navigate = useNavigate();
    const user = useAuth();
    const dispatch = useAppDispatch();
    const [isMetaMaskConnected, setIsMetaMaskConnected] = useState(false);
    console.log('isMetaMaskConnected: ', isMetaMaskConnected);
    const WebEnabled = useAppSelector(getWeb3Enabled);

    const [getNftByIdMutation, { isLoading }] = useLazyGetAdminNftByIdQuery();
    const [setonBid] = useSetNftOnBidMutation();
    const [getUser] = useLazyGetUsersQuery();
    const [giftNftMethod] = useGiftNftMutation();
    const [bidActionMethod] = useHandleBidActionMutation();
    const [mintNftMutation] = useMintNftMutation();
    const [redeemCoupon, redeemCouponData] = useRedeemCouponMutation();

    const [nftData, setNftData] = useState<Nft | null>(null);
    const [customer, setCustomer] = useState('');
    const [customerWallet, setCustomerWallet] = useState('');
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(0);
    const [allUser, setAllUser] = useState<WebUser[]>([]);
    const [metaMaskOwner, setMetaMaskOwner] = useState('');

    const [approveStatus, setApproveStatus] = useState(false);

    const handleChange2 = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleChangeUserSelect = (
        event: React.ChangeEvent<object>,
        value: WebUser | null
    ) => {
        if (value !== null) {
            setCustomer(value?._id);
            setCustomerWallet(value?.walletAddress);
        } else {
            setCustomer(''); // Handle the case where no option is selected
            setCustomerWallet('');
        }
    };

    //handle set on redemmed
    const handleSetOnRedeemed = async (couponId: string) => {
        console.log('id: ', id);
        try {
            const response = await redeemCoupon({ id: couponId }).unwrap();
            if (response?.statusCode === 200) {
                successToast('Coupon Redeemed Successfully');
                if (id) {
                    getNftById(id);
                }
            } else {
                errorToast(response?.message || '');
            }
        } catch (error: any) {
            errorToast(error?.data?.message || '');
        }
    };

    const getAllUser = async () => {
        const token = getFromStorage(STORAGE_KEYS.token);
        dispatch(
            setCredentials({
                user: user,
                token: token,
            })
        );
        try {
            const res = await getUser({
                size: 12,
                page: 1,
                query: '',
                pagination: false,
            }).unwrap();
            if (res?.statusCode === 200) {
                setAllUser(res?.data?.user || []);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getNftById = async (id: string) => {
        const token = getFromStorage(STORAGE_KEYS.token);
        dispatch(
            setCredentials({
                user: user,
                token: token,
            })
        );
        try {
            const response = await getNftByIdMutation({ id }).unwrap();
            if (response?.statusCode === 200) {
                setNftData(response?.data);
            }
        } catch (error: any) {
            errorToast(error?.data?.message || '');
        }
    };

    //gift nft api
    const handleGiftNftApi = async () => {
        if (!customer) {
            errorToast('Select Customer first');
            return;
        }
        let data = {
            userId: customer,
        };
        try {
            setLoading(true);
            const body = generateEncryptedKeyBody(data) as CommonBody;
            const result = await giftNftMethod({
                body,
                nftId: nftData?._id || '',
            }).unwrap();
            setLoading(false);
            if (result?.statusCode === 200) {
                setCustomer('');
                successToast('Gift send successfully');
                navigate('/claimed-assets');
            }
        } catch (error: any) {
            setLoading(false);
            errorToast(error?.data?.message || '');
            console.log(error);
        }
    };

    //gift nft blockchain
    const handleGiftBlockchain = async () => {
        try {
            setLoading(true);
            const tokenId = nftData?.uniqueNftId
                ? Number(nftData?.uniqueNftId)
                : '';
            const receiver = customerWallet;
            const accounts = await web3.eth.getAccounts();
            console.log(tokenId, receiver, accounts[0], 'hi');
            let gas = await CommonMarketContract.methods
                .giftNft(receiver, tokenId)
                .estimateGas({ from: accounts[0] });

            await CommonMarketContract.methods
                .giftNft(receiver, tokenId)
                .send({ from: accounts[0], gas: String(gas) })

                .on('transactionHash', function (sHash) {
                    console.log(sHash, 'sHashhhhhhhh');
                });
            handleGiftNftApi();
            setLoading(false);
        } catch (error: any) {
            if (error?.data?.message || error?.message) {
                errorToast(error?.data?.message || error?.message);
            }
            console.log(error, 'sdfjksdjflksdjflsdj32423');
            setLoading(false);
        }
    };

    const handleApproveCheck = async () => {
        if (!approveStatus && nftData?.nftType === 2) {
            console.log('approveStatus: ', approveStatus);
            handleApproval();
        } else if (approveStatus && nftData?.nftType === 2) {
            handleGiftBlockchain();
        }
    };

    const handleApproveCheckForAcceptBid = async (
        item: {
            _id: string;
            nftId: string;
            amount: number;
            bidderUserId: string;
            createdAt: string;
            isBlocked: boolean;
            isDeleted: boolean;
            nftOwnerId: string;
            bidderWalletAddress: string;
        },
        type: string
    ) => {
        if (!approveStatus && nftData?.nftType === 2) {
            try {
                setLoading(true);
                let sAccounts = await web3.eth.getAccounts();
                let sMsgsender = sAccounts[0];
                let operator = await getMarket721();
                let approved = true;

                let gas = await Common721Contract.methods
                    .setApprovalForAll(operator, approved)
                    .estimateGas({ from: sMsgsender });

                Common721Contract.methods
                    .setApprovalForAll(operator, approved)
                    .send({ from: sMsgsender, gas: String(gas) })
                    .on('transactionHash', function (sHash: string) {})
                    .on('receipt', function (receipt: any) {
                        setLoading(false);
                        console.log(receipt, 'llll');
                        if (receipt?.status) {
                            handleBidActionBlockchain(item, type);
                        }
                    });
            } catch (err) {
                console.log(err);
                setLoading(false);
            }
        } else if (approveStatus && nftData?.nftType === 2) {
            handleBidActionBlockchain(item, type);
        }
    };

    const handleGift = async () => {
        if (nftData?.nftType === 1) {
            handleGiftNftApi();
        } else if (window.ethereum) {
            console.log('Metamask is installed.');
            if (window.ethereum.isConnected()) {
                console.log('Metamask is connected.');
                try {
                    const accounts = await window.ethereum.request({
                        method: 'eth_requestAccounts',
                    });
                    if (accounts.length) {
                        console.log(accounts);
                        handleApproveCheck();
                    }
                } catch (error: any) {
                    console.log('error: ', error);
                    if (error?.data?.message || error?.message) {
                        errorToast(error?.data?.message || error?.message);
                    }
                    console.log(error, 'kk');
                }
            } else {
                errorToast(
                    'You need to connect to the metamask to proceed further'
                );
                console.log('Metamask is not connected.');
            }
        } else {
            console.log('Metamask is not installed.');
        }
    };

    //accept or reject bid Api
    const handleBidAction = async (
        item: {
            _id: string;
            nftId: string;
            amount: number;
            bidderUserId: string;
            createdAt: string;
            isBlocked: boolean;
            isDeleted: boolean;
            nftOwnerId: string;
            bidderWalletAddress: string;
        },
        type: string
    ) => {
        let data = {
            bidderUserId: item?.bidderUserId || 0,
            bid: type,
        };

        try {
            setLoading(true);
            const body = generateEncryptedKeyBody(data) as CommonBody;
            const result = await bidActionMethod({
                body,
                nftId: nftData?._id || '',
            }).unwrap();
            setLoading(false);
            if (result?.statusCode === 200 && id) {
                getNftById(id);
                successToast(
                    type === 'accept'
                        ? 'Bid accepted successfully'
                        : 'Bid rejected successfully'
                );
                if (type === 'accept') {
                    navigate('/claimed-assets');
                }
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    //accept or reject bid BlockChain
    const handleBidActionBlockchain = async (
        item: {
            _id: string;
            nftId: string;
            amount: number;
            bidderUserId: string;
            createdAt: string;
            isBlocked: boolean;
            isDeleted: boolean;
            nftOwnerId: string;
            bidderWalletAddress: string;
        },
        type: string
    ) => {
        const id = nftData?.uniqueNftId ? Number(nftData?.uniqueNftId) : '';
        const accounts = await web3.eth.getAccounts();
        const user = item?.bidderWalletAddress;
        if (nftData?.nftType === 2 && type === 'reject') {
            try {
                setLoading(true);
                console.log(id, user, accounts[0], 'lplplplpl');
                let gas = await CommonMarketContract.methods
                    .rejectBid(id, user)
                    .estimateGas({ from: accounts[0] });

                await CommonMarketContract.methods
                    .rejectBid(id, user)
                    .send({ from: accounts[0], gas: String(gas) })
                    .on('transactionHash', function (sHash) {
                        console.log(sHash, 'sHashhhhhhhh');
                    })
                    .on('receipt', function (receipt) {
                        setLoading(false);
                        console.log(receipt, 'receipt>>>>>>>');
                        handleBidAction(item, type);
                    });
            } catch (error: any) {
                setLoading(false);
                if (error?.data?.message || error?.message) {
                    errorToast(error?.data?.message || error?.message);
                }
                console.log(error, 'llllll');
                setLoading(false);
            }
        } else if (nftData?.nftType === 2 && type === 'accept') {
            try {
                setLoading(true);

                let gas = await CommonMarketContract.methods
                    .acceptBid(id, user)
                    .estimateGas({ from: accounts[0] });

                await CommonMarketContract.methods
                    .acceptBid(id, user)
                    .send({ from: accounts[0], gas: String(gas) })
                    .on('transactionHash', function (sHash) {
                        console.log(sHash, 'sHashhhhhhhh');
                    })
                    .on('receipt', function (receipt) {
                        setLoading(false);
                        console.log(receipt, 'receipt>>>>>>>');
                        handleBidAction(item, type);
                    });
            } catch (error: any) {
                setLoading(false);
                if (error?.data?.message || error?.message) {
                    errorToast(error?.data?.message || error?.message);
                }
                console.log(error, 'llllll');
            }
        }
    };

    const handleAcceptRejectBid = async (
        item: {
            _id: string;
            nftId: string;
            amount: number;
            bidderUserId: string;
            createdAt: string;
            isBlocked: boolean;
            isDeleted: boolean;
            nftOwnerId: string;
            bidderWalletAddress: string;
        },
        type: string
    ) => {
        console.log('type: ', type);
        if (nftData?.nftType === 1) {
            handleBidAction(item, type);
        } else if (window.ethereum) {
            console.log('Metamask is installed.');
            if (window.ethereum.isConnected()) {
                console.log('Metamask is connected.');
                try {
                    const accounts = await window.ethereum.request({
                        method: 'eth_requestAccounts',
                    });
                    if (accounts.length) {
                        console.log(accounts);

                        if (type === 'accept') {
                            handleApproveCheckForAcceptBid(item, 'accept');
                        } else {
                            handleBidActionBlockchain(item, type);
                        }
                    }
                } catch (error: any) {
                    if (error?.data?.message || error?.message) {
                        errorToast(error?.data?.message || error?.message);
                    }
                    console.log(error, 'llllll');
                    setLoading(false);
                }
            } else {
                errorToast(
                    'You need to connect to the metamask to proceed further'
                );
                console.log('Metamask is not connected.');
            }
        } else {
            console.log('Metamask is not installed.');
        }
    };

    //set on bid
    const handleSetOnBidApi = async () => {
        const body = {
            basePrice: nftData?.basePrice || 0,
            bid: nftData?.sellType === 1 ? 'remove' : 'add',
        };

        if (id) {
            try {
                setLoading(true);
                let encryptedBody = generateEncryptedKeyBody(
                    body
                ) as CommonBody;

                const response = await setonBid({
                    body: encryptedBody,
                    nftId: id,
                }).unwrap();
                if (response?.statusCode === 200) {
                    getNftById(id);
                    setLoading(false);
                    successToast(
                        nftData?.sellType === 1
                            ? 'Remove from bid'
                            : 'Nft set on bid successfully'
                    );
                } else {
                    errorToast(response?.message || '');
                }
            } catch (error: any) {
                if (error?.data?.message || error?.message) {
                    errorToast(error?.data?.message || error?.message);
                }
                console.log(error, 'llllll');
                setLoading(false);
            }
        }
    };

    //set on bid blockchain
    const handleBidBlockchain = async () => {
        const tokenId = nftData?.uniqueNftId
            ? Number(nftData?.uniqueNftId)
            : '';
        const accounts = await web3.eth.getAccounts();

        if (nftData?.nftType === 2 && nftData?.sellType === 1) {
            try {
                setLoading(true);
                console.log('removeFromBidding: ', tokenId);
                let gas = await CommonMarketContract.methods
                    .removeFromBidding(tokenId)
                    .estimateGas({ from: accounts[0] });
                await CommonMarketContract.methods
                    .removeFromBidding(tokenId)
                    .send({ from: accounts[0], gas: String(gas) })
                    .on('transactionHash', function (sHash) {
                        console.log(sHash, 'sHashhhhhhhh');
                    })
                    .on('receipt', function (receipt) {
                        console.log(receipt, 'receipt>>>>>>>');
                        handleSetOnBidApi();
                        setLoading(false);
                    });
            } catch (error: any) {
                if (error?.data?.message || error?.message) {
                    errorToast(error?.data?.message || error?.message);
                }
                console.log(error, 'handleBidBlockchain');
                setLoading(false);
            }
        } else if (
            nftData?.nftType === 2 &&
            (nftData?.sellType === 0 || nftData?.sellType === 4)
        ) {
            try {
                console.log('setOnBidding', tokenId);
                setLoading(true);
                let gas = await CommonMarketContract.methods
                    .setOnBidding(tokenId)
                    .estimateGas({ from: accounts[0] });
                await CommonMarketContract.methods
                    .setOnBidding(tokenId)
                    .send({ from: accounts[0], gas: String(gas) })
                    .on('transactionHash', function (sHash) {
                        console.log(sHash, 'sHashhhhhhhh');
                    })
                    .on('receipt', function (receipt) {
                        console.log(receipt, 'receipt>>>>>>>');
                        handleSetOnBidApi();
                        setLoading(false);
                    });
            } catch (error: any) {
                if (error?.data?.message || error?.message) {
                    errorToast(error?.data?.message || error?.message);
                }
                console.log(error, 'handleBidBlockchain2');
                setLoading(false);
            }
        }
    };

    const handleSetOnBid = async () => {
        console.log('handleSetOnBid: ');
        if (nftData?.nftType === 1) {
            handleSetOnBidApi();
        } else if (window.ethereum) {
            console.log('Metamask is installed.');
            if (window.ethereum.isConnected()) {
                console.log('Metamask is connected.');
                try {
                    const accounts = await window.ethereum.request({
                        method: 'eth_requestAccounts',
                    });
                    if (accounts.length) {
                        console.log(accounts);
                        handleBidBlockchain();
                    }
                } catch (error: any) {
                    if (error?.data?.message || error?.message) {
                        errorToast(error?.data?.message || error?.message);
                    }
                    console.log(error, 'handleSetOnBid');
                    setLoading(false);
                }
            } else {
                errorToast(
                    'You need to connect to the metamask to proceed further'
                );
                console.log('Metamask is not connected.');
            }
        } else {
            console.log('Metamask is not installed.');
        }
    };

    //mint nft api
    const handleMintApi = async () => {
        const token = getFromStorage(STORAGE_KEYS.token);
        try {
            setLoading(true);

            const result = await mintNftMutation({
                nftId: nftData?._id || '',
            }).unwrap();
            setLoading(false);
            if (result?.statusCode === 200) {
                // getNftByIdData();
                successToast('Nft mint successfully');
                navigate('/claimed-assets');
            }
        } catch (error: any) {
            setLoading(false);
            errorToast(error?.data?.message || '');
            console.log(error);
        }
    };

    //mint nft blockchain
    const handleMintContractFunction = async () => {
        try {
            setLoading(true);
            const tokenId = nftData?.uniqueNftId
                ? Number(nftData?.uniqueNftId)
                : '';
            const tokenUri = nftData?.image;
            const i2ePoints = nftData?.basePrice || 0;
            const carbonCreditPoints = nftData?.carbonCredit || 0;
            const accounts = await web3.eth.getAccounts();
            console.log('accounts: ', accounts);

            let gas = await CommonMarketContract.methods
                .mintUser(tokenId, tokenUri, i2ePoints, carbonCreditPoints)
                .estimateGas({ from: accounts[0] });

            await CommonMarketContract.methods
                .mintUser(tokenId, tokenUri, i2ePoints, carbonCreditPoints)
                .send({ from: accounts[0], gas: String(gas) })

                .on('transactionHash', function (sHash) {
                    console.log(sHash, 'sHashhhhhhhh');
                });
            // .on("receipt", function (receipt) {
            //   console.log(receipt, "receipt>>>>>>>");
            // });
            handleMintApi();
            setLoading(false);
        } catch (error: any) {
            if (error?.data?.message || error?.message) {
                errorToast(error?.data?.message || error?.message);
            }
            console.log(error, 'handleMintContractFunction');
            setLoading(false);
        }
    };

    const handleMint = async () => {
        const token = getFromStorage(STORAGE_KEYS.token);
        if (window.ethereum) {
            console.log('Metamask is installed.');
            if (window.ethereum.isConnected()) {
                console.log('Metamask is connected.');
                try {
                    const accounts = await window.ethereum.request({
                        method: 'eth_requestAccounts',
                    });
                    if (accounts.length) {
                        console.log(accounts);
                        handleMintContractFunction();
                        // if (token && accounts[0] !== user?.walletAddress) {
                        //   errorToast("Please connect to a valid wallet address");
                        // } else {
                        //   handleMintContractFunction();
                        // }
                    }
                } catch (error: any) {
                    if (error?.data?.message || error?.message) {
                        errorToast(error?.data?.message || error?.message);
                    }
                    console.log(error, 'kk');
                }
            } else {
                errorToast(
                    'You need to connect to the metamask to proceed further'
                );
                console.log('Metamask is not connected.');
            }
        } else {
            console.log('Metamask is not installed.');
        }
    };

    const getMarket721 = async () => {
        try {
            let sResult = await Common721Contract.methods.market().call();
            return sResult;
        } catch (err) {
            console.log(err);
        }
    };

    const handleIsApproved = async () => {
        console.log('handleIsApproved: ');
        let sAccounts = await web3.eth.getAccounts();
        let owner = sAccounts[0];
        let operator = CommonMarketAddress;
        console.log('sAccounts: ', sAccounts, 'qq', owner, 'qq', operator);
        try {
            let approval = Common721Contract.methods
                .isApprovedForAll(owner, operator)
                // @ts-ignore
                .call(function (err: any, status: any) {
                    console.log(status, 'lklklklklk>>>>>>');

                    return status;
                });
            return approval;
        } catch (error: any) {
            if (error?.data?.message || error?.message) {
                errorToast(error?.data?.message || error?.message);
            }
            console.log(error, 'handleIsApproved');
            setLoading(false);
        }
    };

    const handleApproval = async () => {
        try {
            setLoading(true);
            let sAccounts = await web3.eth.getAccounts();
            let sMsgsender = sAccounts[0];
            let operator = await getMarket721();
            let approved = true;

            let gas = await Common721Contract.methods
                .setApprovalForAll(operator, approved)
                .estimateGas({ from: sMsgsender });

            Common721Contract.methods
                .setApprovalForAll(operator, approved)
                .send({ from: sMsgsender, gas: String(gas) })
                .on('transactionHash', function (sHash: string) {})
                .on('receipt', function (receipt: any) {
                    setLoading(false);
                    console.log(receipt, 'handleApproval');
                    if (receipt?.status) {
                        handleGiftBlockchain();
                    }
                });
        } catch (error: any) {
            if (error?.data?.message || error?.message) {
                errorToast(error?.data?.message || error?.message);
            }
            console.log(error, 'handleApproval12');
            setLoading(false);
        }
    };

    const handleCheck = async () => {
        const res = (await handleIsApproved()) as any;
        console.log(res, 'hgfhfhdsghfdgs');
        setApproveStatus(res);
    };

    const getNftDetails = async () => {
        let id = nftData?.uniqueNftId ? Number(nftData?.uniqueNftId) : '';
        try {
            let sResult = (await CommonMarketContract.methods
                .getAssetDetails(id)
                .call()) as any;
            console.log(sResult, 'fsfjksdhfjshdfj');
            setMetaMaskOwner(sResult?.owner || '');

            return sResult;
        } catch (error: any) {
            setMetaMaskOwner('');
            // if (error?.data?.message || error?.message) {
            //   errorToast(error?.data?.message || error?.message);
            // }
            console.log(error);
        }
    };

    useEffect(() => {
        if (isMetaMaskConnected && nftData?.nftType === 2) {
            console.log(
                'isMetaMaskConnected: ',
                isMetaMaskConnected,
                nftData?.nftType
            );
            getNftDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMetaMaskConnected, nftData]);

    useEffect(() => {
        if (id && id !== 'add') {
            getNftById(id);
            getAllUser();
        }
    }, []);

    useEffect(() => {
        if (isMetaMaskConnected) {
            handleCheck();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMetaMaskConnected]);

    useEffect(() => {
        if (window.ethereum) {
            window.ethereum
                .request({ method: 'eth_accounts' })
                .then((accounts: string | any[]) => {
                    if (accounts.length > 0) {
                        setIsMetaMaskConnected(true);
                    } else {
                        setIsMetaMaskConnected(false);
                    }
                })
                .catch((error: any) => {
                    console.error(error);
                    setIsMetaMaskConnected(false);
                });
        } else {
            setIsMetaMaskConnected(false);
        }
    }, []);

    return (
        <MainContainer name="Contact & Support">
            <Loader
                isLoad={isLoading || loading || redeemCouponData?.isLoading}
            />
            <div className="manage_blog view_nft">
                <div className="sec_head fs_22">
                    <h2>View NFT</h2>
                    <div className="btn_group">
                        <button
                            className="btn btn_sm btn_primary"
                            onClick={() =>
                                navigate(
                                    state?.couponId
                                        ? `/manage-coupons/coupon-detail/${state?.couponId}`
                                        : '/claimed-assets'
                                )
                            }
                        >
                            Back
                        </button>
                    </div>
                </div>

                <div className="form_title fs_16">
                    <h2>NFT Detail</h2>
                </div>
                <div className="form_info">
                    <ul className="gap_p">
                        <li className="w_100">
                            <p>NFT Image</p>
                            <div className="prfl_pic w_30">
                                <div className="upload_image">
                                    <div className="upload_image_holder">
                                        <figure>
                                            <img
                                                src={nftData?.image || ''}
                                                alt=""
                                            />
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <p>NFT Name</p>
                            <h6>{nftData?.name || ''}</h6>
                        </li>
                        <li>
                            <p>Carban Credit</p>
                            <h6>{nftData?.carbonCredit || '0'}</h6>
                        </li>
                        <li>
                            <p>Category Name</p>
                            <h6>{nftData?.category?.name || ''}</h6>
                        </li>
                        <li>
                            <p>I2E Point</p>
                            <h6>{nftData?.basePrice || '0'}</h6>
                        </li>
                        {nftData?.nftType === 2 ? (
                            <li>
                                <p>Owner Id</p>
                                <h6>{metaMaskOwner || ''}</h6>
                            </li>
                        ) : null}

                        <li>
                            <p>Date</p>
                            <h6>
                                {' '}
                                {moment(nftData?.createdAt).format('MMM Do YY')}
                            </h6>
                        </li>
                        <li className="w_100">
                            <p>Description</p>
                            <h6>{nftData?.description || ''}</h6>
                        </li>
                    </ul>
                </div>

                <div className="form_title fs_16 mt_40">
                    <h2>User Detail</h2>
                </div>
                <div className="form_info">
                    <ul className="gap_p">
                        <li>
                            <p>Name</p>
                            <h6>{nftData?.couponClaimedBy?.name || ''}</h6>
                        </li>
                        {/* <li>
                <p>Contact Number</p>
                <h6>
                  {" "}
                  {nftData?.couponClaimedBy?.countryCode
                    ? (nftData?.couponClaimedBy?.countryCode.includes("+")
                        ? ""
                        : "+") + nftData?.couponClaimedBy?.countryCode
                    : null}{" "}
                  {nftData?.couponClaimedBy?.phone || ""}
                </h6>
              </li> */}
                        <li>
                            <p>Email</p>
                            <h6>{nftData?.couponClaimedBy?.email || ''}</h6>
                        </li>
                    </ul>
                </div>

                {/* coupon detail */}
                <div className="coupon coupon_dtl_page ">
                    <div className="form_title fs_16">
                        <h2>Coupon Info</h2>
                    </div>
                    <div className="form_info">
                        <ul className="gap_p">
                            <li className="w_100">
                                <p>Coupon Image</p>
                                <div className="coupon_card">
                                    <div className="coupon_card_dtl">
                                        <figure>
                                            <span>Gift Voucher</span>
                                            <img
                                                src="/images/bar_code.svg"
                                                alt=""
                                            />
                                        </figure>

                                        <div className="coupon_dtl fs_28 text_center">
                                            <figure className="uploaded_coupon">
                                                <img
                                                    src={
                                                        // user?.role === 1 &&

                                                        nftData?.claimedCoupon
                                                            ?.couponId?.image ||
                                                        nftData?.organization
                                                            ?.b2b[0]?.image
                                                    }
                                                    alt=""
                                                />
                                            </figure>
                                        </div>

                                        <div className="coupon_btn">
                                            <button className="btn btn_primary">
                                                {nftData?.claimedCoupon
                                                    ?.couponId?.discountType ===
                                                1
                                                    ? 'AED '
                                                    : ''}
                                                {nftData?.claimedCoupon
                                                    ?.couponId
                                                    ?.effectiveValue ||
                                                    '0'}{' '}
                                                {nftData?.claimedCoupon
                                                    ?.couponId?.discountType ===
                                                2
                                                    ? '%'
                                                    : ''}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            {nftData?.claimedCoupon?.status === 2 ? (
                                <div className="redeemedBtn">
                                    <button
                                        className="btn btn_sm btn_primary"
                                        onClick={() =>
                                            handleSetOnRedeemed(
                                                nftData?.claimedCoupon?._id
                                            )
                                        }
                                    >
                                        Set as Redeemed
                                    </button>
                                </div>
                            ) : null}

                            <li>
                                <p>Coupon Code</p>
                                <h6>
                                    {nftData?.claimedCoupon?.couponId?.code ||
                                        ''}
                                </h6>
                            </li>
                            <li>
                                <p>Coupon Status</p>
                                <h6>
                                    {nftData?.claimedCoupon?.status === 1
                                        ? 'Redeemed'
                                        : nftData?.claimedCoupon?.status === 2
                                        ? 'Reserved'
                                        : 'Available'}
                                </h6>
                            </li>
                            <li>
                                <p>Value</p>
                                <h6>
                                    {nftData?.claimedCoupon?.couponId
                                        ?.discountType === 1
                                        ? 'AED '
                                        : ''}
                                    {nftData?.claimedCoupon?.couponId
                                        ?.effectiveValue || '0'}{' '}
                                    {nftData?.claimedCoupon?.couponId
                                        ?.discountType === 2
                                        ? '%'
                                        : ''}
                                </h6>
                            </li>
                            <li>
                                <p>Start Date</p>
                                <h6>
                                    {moment(
                                        nftData?.claimedCoupon?.couponId
                                            ?.startDate
                                    ).format('LL')}
                                </h6>
                            </li>
                            {nftData?.claimedCoupon?.couponId?.endDate ? (
                                <li>
                                    <p>End Date</p>
                                    <h6>
                                        {nftData?.claimedCoupon?.couponId
                                            ?.endDate
                                            ? moment(
                                                  nftData?.claimedCoupon
                                                      ?.couponId?.endDate
                                              ).format('LL')
                                            : ''}
                                    </h6>
                                </li>
                            ) : null}

                            <li>
                                <p>Coupon Type</p>
                                <h6>
                                    {nftData?.claimedCoupon?.couponId
                                        ?.couponType === 2
                                        ? 'Direct'
                                        : 'Indirect'}
                                </h6>
                            </li>
                            {/* <li>
                  <p>Total Coupon </p>
                  <h6>{coupon?.quantity || 0}</h6>
                </li> */}
                            {/* <li>
                  <p>Coupon Remaining</p>
                  <h6>{coupon?.quantityLeft || 0}</h6>
                </li> */}
                            {/* {user?.role === 1 ? (
                  <li>
                    <p>Assign To</p>
                    {coupon?.assignedTo?.length
                      ? coupon?.assignedTo?.map((item: User, i) => (
                          <h6 key={i}>
                            {item?.companyName || ""}{" "}
                            <span>({item?.companyWebSite || ""})</span>
                          </h6>
                        ))
                      : null}
                  </li>
                ) : null} */}

                            <li className="w_100">
                                <p>Short Note</p>
                                <h6>
                                    {nftData?.claimedCoupon?.couponId
                                        ?.description || ''}
                                </h6>
                            </li>
                            {nftData?.claimedCoupon?.couponDescription ? (
                                <li className="w_100">
                                    <p>User Input</p>
                                    <h6>
                                        {nftData?.claimedCoupon
                                            ?.couponDescription || ''}
                                    </h6>
                                </li>
                            ) : null}
                        </ul>
                    </div>

                    {/* <div className="form_info">
            <ul className="gap_p">
              <li className="w_100">
                <p>NFT Image</p>
                <div className="prfl_pic w_30">
                  <div className="upload_image">
                    <div className="upload_image_holder">
                      <figure>
                        <img
                          src={nft?.image || "/images/explore_attachment_3.jpg"}
                          alt=""
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <p>NFT Name</p>
                <h6>{nft?.name || ""}</h6>
              </li>
              <li>
                <p>Brand Name</p>
                <h6>{nft?.brandName || ""}</h6>
              </li>
              <li>
                <p>Carban Credit</p>
                <h6>{nft?.carbonCredit || ""}</h6>
              </li>
              <li className="w_100">
                <p>Description</p>
                <h6>{nft?.description || ""}</h6>
              </li>
              {nft?.userId ? (
                <>
                  <li>
                    <p>User Name</p>
                    <h6>{"Kishore" || ""}</h6>
                  </li>
                  <li>
                    <p>User Contact Number</p>
                    <h6>9877454492</h6>
                  </li>
                  <li>
                    <p>User Email</p>
                    <h6>kishore@apptunix.com</h6>
                  </li>
                </>
              ) : null}
            </ul>
          </div> */}
                </div>
                {/* coupon detail */}

                {user?.role === 1 && WebEnabled ? (
                    <>
                        {' '}
                        <div className="form_title fs_16 mt_40">
                            <h2>Set NFT on</h2>
                        </div>
                        <div className="custom_tabs">
                            <Tabs
                                value={value}
                                onChange={handleChange2}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="basic tabs example"
                                className="custom_tabs_links"
                            >
                                <Tab label="Bid" {...a11yProps(0)} />
                                <Tab label="Gift" {...a11yProps(1)} />
                                {valueNum && valueNum === '1' ? null : (
                                    <Tab label="Mint" {...a11yProps(2)} />
                                )}
                            </Tabs>
                            <CustomTabPanel value={value} index={0}>
                                <div className="btn_flex">
                                    <button
                                        className="btn btn_primary"
                                        onClick={handleSetOnBid}
                                    >
                                        {nftData?.sellType === 1
                                            ? 'Remove From Bid'
                                            : 'Set On Bid'}
                                    </button>
                                </div>
                                {nftData?.sellType === 1 &&
                                nftData?.bidding?.length ? (
                                    <TableContainer
                                        className="table_container"
                                        style={{ paddingTop: 20 }}
                                    >
                                        <Table
                                            sx={{ minWidth: 650 }}
                                            aria-label="simple table"
                                            className="table"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell> Name</TableCell>
                                                    <TableCell align="center">
                                                        Bid Amount
                                                    </TableCell>
                                                    <TableCell>Date</TableCell>
                                                    <TableCell>
                                                        Action
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {nftData?.bidding?.map((item) => (
                                                <TableBody>
                                                    <TableCell>
                                                        {' '}
                                                        {item?.bidderName || ''}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {item?.amount || 0}
                                                    </TableCell>
                                                    <TableCell>
                                                        {moment(
                                                            item?.createdAt
                                                        ).format('D MMM YYYY')}
                                                    </TableCell>
                                                    <TableCell>
                                                        <ul className="action_btn">
                                                            <li
                                                                onClick={() =>
                                                                    handleAcceptRejectBid(
                                                                        item,
                                                                        'accept'
                                                                    )
                                                                }
                                                            >
                                                                <button type="button">
                                                                    <DoneIcon />
                                                                </button>
                                                            </li>

                                                            <li>
                                                                <button
                                                                    type="button"
                                                                    onClick={() =>
                                                                        handleAcceptRejectBid(
                                                                            item,
                                                                            'reject'
                                                                        )
                                                                    }
                                                                >
                                                                    <CloseIcon />
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </TableCell>
                                                </TableBody>
                                            ))}
                                        </Table>
                                    </TableContainer>
                                ) : null}
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                                <div
                                    className="btn_flex"
                                    style={{ width: '50%' }}
                                >
                                    <div className="form_control form_control_bg w_50">
                                        <label>Choose User</label>
                                        <Autocomplete
                                            className="prfl_autocmplt"
                                            disablePortal
                                            id="combo-box-demo"
                                            onChange={handleChangeUserSelect}
                                            options={
                                                allUser?.length ? allUser : []
                                            }
                                            getOptionLabel={(option) =>
                                                `${option?.name} - ${option?.email}`
                                            }
                                            sx={{ width: 'auto' }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Customer"
                                                />
                                            )}
                                        />
                                    </div>
                                    <button
                                        className="btn btn_primary"
                                        onClick={handleGift}
                                    >
                                        Gift
                                    </button>
                                </div>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={2}>
                                <div className="btn_flex">
                                    <button
                                        className="btn btn_primary"
                                        onClick={handleMint}
                                    >
                                        Mint on Wallet
                                    </button>
                                </div>
                            </CustomTabPanel>
                        </div>
                    </>
                ) : null}
            </div>
        </MainContainer>
    );
}
